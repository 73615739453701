import { Suspense, useEffect, useState } from "react";
import { RemoteTrackPublication } from "livekit-client";
import { VideoRenderer } from "@livekit/react-core";
import { motion } from "framer-motion";
import { Video } from "./Video";
import { ThreeD } from "./ThreeD";
import { Quizz } from "./Quizz";
import { Pdf } from "./Pdf";
import { RemoveIcon } from "./RemoveIcon";
import { useStore } from "@/Store";
import { S3_URL } from "@/Config";
import { MediaType } from "@/Contracts";
import { useUser } from "@/Hooks/useUser";
import { pusher, reactionsChannel } from "@/Services/Pusher";

export const StreamCanvas = () => {
  const media = useStore((state) => state.mediaStreamed);
  const track = useStore((state) => state.trackStreamed);

  const [showVimeo, setShowVimeo] = useState(true);

  const { roles } = useUser();

  const [viewerCount, setViewerCount] = useState(0);

  useEffect(() => {
    if (!roles.includes("admin")) return;

    reactionsChannel.bind("pusher:subscription_count", (data: any) => {
      setViewerCount(data.subscription_count);
    });

    return () => {
      pusher.unsubscribe("pusher:subscription_count");
    };
  }, []);

  return (
    <Suspense fallback={null}>
      {track && track.isSubscribed && (
        <div className="w-full h-full flex-1 object-contain absolute inset-0 mx-auto p-4">
          <VideoRenderer
            height="100%"
            objectFit="contain"
            className="mx-auto tranform !rotate-0"
            track={track.track as any}
            isLocal={!(track instanceof RemoteTrackPublication)}
          />
        </div>
      )}

      {!track && media ? (
        <motion.div
          key={media.id}
          className="w-full h-full flex flex-col m-auto relative"
        >
          <RemoveIcon />
          {media.type === MediaType.Image && (
            <img
              className="object-contain flex-1 absolute inset-0 h-full mx-auto p-4"
              src={`${S3_URL}${media.url}`}
              alt={media.name}
            />
          )}

          {media.type === MediaType.Pdf && <Pdf media={media} />}
          {media.type === MediaType.Video && <Video media={media} />}
          {media.type === MediaType.ThreeD && <ThreeD media={media} />}
          {media.type === MediaType.Quiz && <Quizz media={media} />}
        </motion.div>
      ) : null}

      {!track && !media && (
        <div className="h-full flex flex-col m-auto relative container mx-auto">
          <div className="h-full">
            {roles.includes("admin") && (
              <div className="absolute top-5 right-5 z-10 uppercase font-condensed">
                <span>
                  {viewerCount} viewers <br />
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => setShowVimeo(!showVimeo)}
                >
                  {showVimeo ? "Masquer" : "Afficher"} le live
                </span>
              </div>
            )}

            {showVimeo && (
              <div className="w-full">
                <iframe
                  src="https://vimeo.com/event/2689084/embed"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            )}
          </div>
        </div>
      )}
    </Suspense>
  );
};
