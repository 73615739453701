import type { Media } from "@/Contracts";
import { getS3Path } from "@/Utils";

interface Props {
  media: Media;
}

export const Pdf = (props: Props) => {
  return <iframe src={getS3Path(props.media.url)} className="h-full" />;
};
