import { useState } from "react";
import { InlineMediaSelector } from "./Inline";
import { ExpandedMediaSelector } from "./Expanded";
import type { Media, MediaType } from "@/Contracts/index";

export interface Props {
  filterMediaTypes?: MediaType[];
  onMediaSelected?: (media: Media) => void;
}

export const MediaSelector = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!isExpanded) {
    return (
      <InlineMediaSelector
        filterMediaTypes={props.filterMediaTypes}
        onExpand={() => setIsExpanded(true)}
        onMediaSelected={props.onMediaSelected}
      />
    );
  }

  return <ExpandedMediaSelector onMediaSelected={props.onMediaSelected} />;
};
