import { useReactions } from "../Reactions/useReactions";

interface Props {
  onPollClick?: () => void;
}

export const MobileToolbar = (props: Props) => {
  const { sendReaction } = useReactions();

  return (
    <div className="md:hidden c-mobile-toolbar px-0 relative w-full flex flex-col items-center py-2 pointer-events-auto grow-2">
      <div className="flex justify-between w-full space-x-2">
        <div className="flex space-x-2">
          <div
            className="bg-[#1B1B1B] p-2 rounded-full"
            onClick={() => sendReaction("🔥")}
          >
            <div className="cursor-pointer c-reactions__emoji" tabIndex={0}>
              🔥
            </div>
          </div>
          <div
            className="bg-[#1B1B1B] p-2 rounded-full"
            onClick={() => sendReaction("🐻")}
          >
            <div className="cursor-pointer c-reactions__emoji" tabIndex={0}>
              🐻
            </div>
          </div>
          <div
            className="bg-[#1B1B1B] p-2 rounded-full"
            onClick={() => sendReaction("🌪️")}
          >
            <div className="cursor-pointer c-reactions__emoji" tabIndex={0}>
              🌪️
            </div>
          </div>{" "}
          <div
            className="bg-[#1B1B1B] p-2 rounded-full"
            onClick={() => sendReaction("🦉")}
          >
            <div className="cursor-pointer c-reactions__emoji" tabIndex={0}>
              🦉
            </div>
          </div>
        </div>

        <div
          className="bg-[#1B1B1B] px-4 rounded-lg flex items-center font-condensed uppercase"
          onClick={() => props.onPollClick?.()}
        >
          Sondage
        </div>
      </div>
    </div>
  );
};
