import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { usePinnedMessage } from "./Hooks/usePinnedMessage";
import { DELETE_MESSAGE } from "./Gql";
import type { ChatMessage } from "./Contracts";
import Pin from "@/Assets/Icons/pin.svg";
import Block from "@/Assets/Icons/block.svg";
import { useUser } from "@/Hooks/useUser";
import { Api } from "@/Services/Api";

export const AdminActions = (props: { message: ChatMessage }) => {
  const { useSetPinnedMessage } = usePinnedMessage();
  const setPinnedMessage = useSetPinnedMessage();
  const { roles } = useUser();

  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    variables: { messageId: props.message.id },
  });

  if (!roles.includes("admin")) return <></>;

  return (
    <div className="flex items-center gap-x-1.5 ml-2 text-white text-left mt-4">
      <i
        className="cursor-pointer text i-maki-cross"
        onClick={async () => {
          await deleteMessage();
          toast.success("Message supprimé");
        }}
      ></i>
      <img
        onClick={() => setPinnedMessage(props.message.id)}
        className="cursor-pointer"
        src={Pin}
      />
      <img
        className="cursor-pointer relative top-[-1px]"
        src={Block}
        onClick={async () => {
          await Api.muteUser(props.message.user_id);
          toast.success("Utilisateur muté");
        }}
      />
    </div>
  );
};
