import { StreamCanvas } from "../../Features/Streamcanvas/index";
import { MobileLayout } from "./MobileLayout";
import { isLandscapeMobile, isSm } from "@/Utils";
import { Reactions } from "@/Features/Reactions/index";
import { MobileToolbar } from "@/Features/MobileToolbar";

export function StageRenderer() {
  if (isSm() || isLandscapeMobile()) {
    return <MobileLayout />;
  }

  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <div className="c-participants-grid__streamed-element relative">
        <StreamCanvas />
      </div>

      <MobileToolbar />

      <div className="grid grid-cols-3 w-full">
        <span></span>
        <span></span>
        <Reactions />
      </div>
    </div>
  );
}
