import clsx from "clsx";
import { useState } from "react";

interface Props {
  items: { label: string; value: string }[];
  onChange: (value: string) => void;
}

export const Tabs = (props: Props) => {
  const [category, setCategory] = useState<string | null>(null);

  const onTabClick = (value: string) => {
    setCategory(value);
    props.onChange(value);
  };

  return (
    <div className="flex gap-4 font-light">
      {props.items.map((item) => (
        <button
          key={item.value}
          className={clsx({
            "cursor-pointer transition px-4 pb-2": true,
            "border-b border-primary font-normal": category === item.value,
          })}
          onClick={onTabClick.bind(null, item.value)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};
