import { useCallback, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { ChatInput } from "./ChatInput";
import { Message } from "./Message";
import { useChat } from "./Hooks/useChat";
import { PinnedMessage } from "./PinnedMessage";
import type { VirtuosoHandle } from "react-virtuoso";
import "./main.css";

export const Chat = () => {
  const virtuoso = useRef<VirtuosoHandle>(null);

  const START_INDEX = 49;
  const INITIAL_ITEM_COUNT = 50;

  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX);

  const {
    messages,
    loading,
    useSubscribeChatMessage,
    loadMoreMessages,
    setMessages,
    prependMessages,
  } = useChat();

  useSubscribeChatMessage();

  const prependItems = useCallback(async () => {
    const messagesToPrepend = 20;
    const nextFirstItemIndex = firstItemIndex - messagesToPrepend;

    const moreMessages = await loadMoreMessages();
    setFirstItemIndex(() => nextFirstItemIndex);
    prependMessages(moreMessages);

    return false;
  }, [firstItemIndex, messages, setMessages]);

  if (loading) return <div>Loading ...</div>;

  return (
    <div className="rounded-xl p-8 px-4 mt-1">
      <PinnedMessage />

      <Virtuoso
        ref={virtuoso}
        className="c-chat-container flex mt-2 justify-start flex-col pr-2 pt-4 flex-1 !md:h-[60vh] overflow-y-auto"
        initialTopMostItemIndex={INITIAL_ITEM_COUNT - 1}
        firstItemIndex={firstItemIndex}
        followOutput={"smooth"}
        data={messages}
        startReached={prependItems}
        itemContent={(_idx, message) => (
          <Message adminActions={true} message={message} />
        )}
      />

      <ChatInput />
    </div>
  );
};
