import { gql } from "@apollo/client";

export const GET_APPLICATION = gql`
  query getApplication($title: String!) {
    application(where: { title: { _eq: $title } }) {
      application_config {
        id
        app_config
      }
      id
      title
    }
  }
`;
