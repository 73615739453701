import Boss from "@/Assets/Bears/boss.png";
import Dai from "@/Assets/Bears/dai.png";
import Intern from "@/Assets/Bears/intern.png";
import Merch from "@/Assets/Bears/merch.png";
import Marketing from "@/Assets/Bears/rp.png";
import Music from "@/Assets/Bears/music.png";

export const RoleSelector = ({
  roles,
  onRoleSelected,
}: {
  roles: string[];
  onRoleSelected: (role: string) => void;
}) => {
  const roleData = {
    boss: {
      name: "Boss",
      image: Boss,
    },
    image: {
      name: "DA Image",
      image: Dai,
    },
    intern: {
      name: "Stagiaire",
      image: Intern,
    },
    marketing: {
      name: "DA Marketing",
      image: Marketing,
    },
    merch: {
      name: "DA Merch",
      image: Merch,
    },
    music: {
      name: "DA Musique",
      image: Music,
    },
  } as Record<string, { name: string; image: string }>;

  return (
    <div className="flex mt-12 flex-col items-center justify-center">
      <h2 className="text-xl font-bold mb-4">Sélectionnez votre rôle</h2>
      <div className="flex flex-wrap gap-8 mt-6 items-center justify-center">
        {roles.map((role) => (
          <div
            key={role}
            className="flex flex-col items-center transition hover:scale-110 cursor-pointer"
            onClick={() => {
              onRoleSelected(role);
            }}
          >
            <img
              src={roleData[role].image || "unknown.png"}
              className="w-24 h-24 bg-primary rounded-2xl"
            />
            <span className="text-xs mt-2">{roleData[role].name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
