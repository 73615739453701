import { useMutation } from "@apollo/client";
import { SET_MEDIA_STREAMED } from "@/Gql/events";

export const useUpdateMediaStreamed = () => {
  return useMutation<
    unknown,
    {
      eventId: string;
      mediaId: string | null;
    }
  >(SET_MEDIA_STREAMED);
};
