import { gql } from "@apollo/client";

/**
 * Get all medias
 */
export const GET_MEDIAS = gql`
  query GetMedias($applicationId: uuid) {
    medias: media(where: { application_id: { _eq: $applicationId } }) {
      id
      name
      type
      url
    }
  }
`;
