import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { useSubscribeMediaStreamed } from "@/Features/Room/Api/subscribeMediaStreamed";
import { useStore } from "@/Store/index";
import { EVENT_SUBSCRIPTION, GET_EVENT } from "@/Gql/events";
import { parseJwt } from "@/Utils";

export const useApp = () => {
  const [searchParams] = useSearchParams();

  const url = searchParams.get("url");
  const token = searchParams.get("token");
  const eventId = searchParams.get("event");

  /**
   * Subscribe and update the streamed media in the store
   */
  const setMediaStreamed = useStore((state) => state.setMediaStreamed);
  const { data: mediaStreamed } = useSubscribeMediaStreamed(eventId!);
  useEffect(() => {
    if (!mediaStreamed?.event_media.length) return;

    setMediaStreamed(mediaStreamed?.event_media[0].media);
  }, [mediaStreamed]);

  /**
   * Get the event based on searchParams
   */
  const setEvent = useStore((state) => state.setEvent);
  const { data: fetchedEvent } = useQuery(GET_EVENT, {
    variables: { eventId: eventId! },
  });
  useEffect(() => {
    if (!fetchedEvent) return;
    setEvent(fetchedEvent.events_by_pk);
  }, [fetchedEvent]);

  /**
   * Subscribe and update the event in the store
   */
  const { data: eventStreamed } = useSubscription(EVENT_SUBSCRIPTION, {
    variables: { eventId: eventId! },
  });

  useEffect(() => {
    if (!eventStreamed) return;
    setEvent(eventStreamed.event);
  }, [eventStreamed]);

  const event = useStore((state) => state.event);

  function isTokenExpired() {
    if (!token) return false;

    const jwt = parseJwt(token!);
    return jwt.exp < Math.floor(Date.now() / 1000);
  }

  return {
    event,
    url,
    token,
    searchParams,
    isMissingParams: !url || !token || !eventId,
    isTokenExpired,
  };
};
