export const IconPin = (props: { className?: string }) => {
  return (
    <svg
      className={props.className}
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.06083 1.52179H2.40084C1.98057 1.52179 1.63994 1.18116 1.63994 0.760895C1.63994 0.340629 1.98057 0 2.40084 0H10.0104C10.4307 0 10.7713 0.340629 10.7713 0.760895C10.7713 1.18116 10.4307 1.52179 10.0104 1.52179H9.35057L9.92128 6.08742H10.0105C11.2712 6.08742 12.2933 7.10947 12.2933 8.37024V10.6531C12.2933 11.0733 11.9525 11.4141 11.5322 11.4141H6.96661V14.4578C6.96661 14.8781 6.62598 15.2187 6.20572 15.2187C5.78545 15.2187 5.44482 14.8781 5.44482 14.4578V11.4141H0.879189C0.458923 11.4141 0.118164 11.0733 0.118164 10.6531V8.37024C0.118164 7.10947 1.14021 6.08742 2.40098 6.08742H2.49028L3.06083 1.52179Z"
        fill="currentColor"
      />
    </svg>
  );
};
