import { Bomp } from "../UI/Bomp";
import { sidebarItems } from "./items";
import { useUser } from "@/Hooks/useUser";

export type SidebarItemId = typeof sidebarItems[number]["id"];

interface Props {
  selected?: SidebarItemId;
  onItemClick?: (id: SidebarItemId) => void;
}

export const Sidebar = (props: Props) => {
  const { roles } = useUser();

  return (
    <div className="absolute top-24 z-[1] left-5 hidden c-sidebar md:block">
      <div className="flex flex-col h-full">
        {sidebarItems
          .filter(
            (item) =>
              !item.roles || item.roles.every((role) => roles.includes(role))
          )
          .map((item) => (
            <Bomp
              key={item.id}
              isSelected={props.selected === item.id}
              onClick={() => props.onItemClick?.(item.id)}
              icon={item.icon}
              iconClassName="text-3xl"
            >
              <p className="font-condensed text-sm mt-2">{item.title}</p>
            </Bomp>
          ))}
      </div>
    </div>
  );
};
