export interface ApplicationConfig {
  appConfig: {
    id: string;
    disableVideo: boolean;
    disableAudio: boolean;
    video: { allowedRoles: string[] };
    audio: { allowedRoles: string[] };
    isWeb3: boolean;
    emojis: string[];
    chatRatePerMinute: number;
    emojisSendThrottle: 1000;
    maxDisplayedEmojis: 10;
  };
}

export enum Applications {
  RXS = "rxs",
  OS = "os",
  PLK = "plk",
}

export enum MediaType {
  Image = "image",
  Video = "video",
  Quiz = "quizz",
  ThreeD = "3d",
  Pdf = "pdf",
}

export interface Media {
  id: string;
  name: string;
  url: string;
  type: MediaType;
}

export interface PollOption {
  id: string;
  pollId: string;
  title: string;
  votesCount: number;
}

export interface Poll {
  id: string;
  title: string;
  type: string;
  closedAt?: string;
  createdAt: string;
  options: PollOption[];
}

export interface Event {
  id: string;
  password: string;
  created_at: string;
  updated_at: string;
  start_at: string;
  client_id: string;
  client: Client;
  user_id: string;
  user: User;
  status_id: number;
  event_status: EventStatus;
  application_id: string;
  application: Application;
  poll: Poll;
  notes: string;
}

export interface Application {
  application_config_id: string;
  id: string;
  title: string;
}

export interface Client {
  id: string;
  created_at: string;
  first_name: string;
  last_name: string;
  updated_at: string;
}

export interface EventStatus {
  name: string;
  id: number;
}

export interface User {
  id: string;
  email: string;
  first_name: string;
  fullname: string;
  last_name: string;
}

/**
 * LMS Contracts
 */
export interface Activity {
  instruction: string;
  title: string;
  questions: Question[];
}

export interface Question {
  correction: string;
  id: string;
  text: string;
  answers: Answer[];
}

export interface Answer {
  is_correct: boolean;
  text: string;
}
