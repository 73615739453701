import { gql } from "@apollo/client";

export const CHAT_SUBSCRIPTION = gql`
  subscription MessageSubscription($eventId: uuid) {
    event_chat_messages(
      where: { event_id: { _eq: $eventId } }
      order_by: { created_at: desc }
      limit: 50
    ) {
      id
      content
      role
      username
      user_id
      created_at
    }
  }
`;

export const FETCH_MORE_MESSAGES = gql`
  query FetchMoreMessages($eventId: uuid!, $lastMessageDate: timestamptz!) {
    event_chat_messages(
      where: {
        _and: [
          { event_id: { _eq: $eventId } }
          { created_at: { _lt: $lastMessageDate } }
        ]
      }
      order_by: { created_at: desc }
      limit: 20
    ) {
      id
      content
      role
      created_at
      user_id
      username
    }
  }
`;

export const FETCH_CHAT_MESSAGES = gql`
  query FetchChatMessages($eventId: uuid!) {
    event_chat_messages(
      where: { event_id: { _eq: $eventId } }
      order_by: { created_at: desc }
      limit: 50
    ) {
      id
      content
      role
      created_at
      user_id
      username
    }
  }
`;

export const INSERT_CHAT_MESSAGE = gql`
  mutation InsertChatMessage(
    $content: String!
    $eventId: uuid!
    $username: String!
  ) {
    insert_event_chat_messages_one(
      object: { content: $content, event_id: $eventId, username: $username }
    ) {
      id
    }
  }
`;

export const SET_PINNED_MESSAGE = gql`
  mutation SetPinnedMessage($eventId: uuid!, $messageId: uuid!) {
    update_events_by_pk(
      pk_columns: { id: $eventId }
      _set: { pinned_message_id: $messageId }
    ) {
      id
    }
  }
`;

export const FETCH_PINNED_MESSAGE = gql`
  query FetchPinnedMessage($eventId: uuid!) {
    events_by_pk(id: $eventId) {
      pinned_message {
        id
        content
        role
        username
        user_id
        created_at
      }
    }
  }
`;

export const PINNED_MESSAGE_SUBSCRIPTION = gql`
  subscription PinnedMessageSubscription($eventId: uuid!) {
    events_by_pk(id: $eventId) {
      id
      pinned_message {
        id
        content
        role
        created_at
        user_id
        username
      }
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($messageId: uuid!) {
    delete_event_chat_messages_by_pk(id: $messageId) {
      id
    }
  }
`;
