import { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useChat } from "./Hooks/useChat";
import { Input } from "@/Components/UI/Input";
import { Button } from "@/Components/UI/Button";
import { useUser } from "@/Hooks/useUser";

export const ChatInput = () => {
  const { sendMessage } = useChat();

  const [input, setInput] = useState("");
  const [rateLimited, setRateLimited] = useState(false);

  const { isOnlyIntern } = useUser();

  async function handleSendMessage() {
    if (isOnlyIntern) {
      return toast.error(
        "Vous ne pouvez pas envoyer de message en tant que stagiaire."
      );
    }

    setInput("");
    const hasBeenSent = await sendMessage({
      content: input,
      username: localStorage.getItem("userName") || "Utilisateur anonyme",
    });

    if (hasBeenSent === "E_TOO_LONG") {
      return toast.error(
        "Votre message est trop long, il ne doit pas dépasser 500 caractères."
      );
    }

    setRateLimited(!hasBeenSent);
  }

  return (
    <div className="w-full gap-x-2 md:w-[360px] c-chat mt-4 relative pr-2 flex items-center">
      <Input
        value={input}
        onChange={setInput}
        onKeyDown={async (event) => {
          if (event.key !== "Enter") return;

          handleSendMessage();
        }}
        className="!px-4 text-sm !rounded-lg !bg-[#545454] !text-white"
        placeholder="Écrire un message"
      ></Input>

      <Button
        className="h-full !rounded-lg font-condensed uppercase text-xs md:text-sm flex items-center !px-4"
        onClick={async () => handleSendMessage()}
      >
        <span className="inline-block i-mingcute-send-plane-line"></span>
      </Button>

      {rateLimited && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="absolute -top-2 -right-2 bg-red-500 text-white px-2 py-1 rounded-lg text-xs"
        >
          Vous envoyez trop de messages ! Attendez un peu.
        </motion.div>
      )}
    </div>
  );
};
