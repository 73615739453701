import clsx from "clsx";

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  type?: "text" | "password" | "email" | "number";
  label?: string;
  size?: "default" | "sm";
}

export const Input = (props: Props) => {
  const size = props.size ?? "default";

  return (
    <div className="flex flex-col flex-1">
      {props.label && <label className="block mb-2">{props.label}</label>}
      <input
        type={props.type ?? "text"}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        onKeyDown={props.onKeyDown}
        className={clsx(
          "text-black outline-none rounded-lg bg-[#242424] text-white",
          props.className,
          {
            "py-2 px-3 text-sm": size === "sm",
            "py-2 px-4 text-base": size === "default",
          }
        )}
        disabled={props.disabled}
      />
    </div>
  );
};
