import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_CHAT_RATE_LIMIT } from "./gql";
import { Tabs } from "@/Components/UI/Tabs";
import { Input } from "@/Components/UI/Input";
import { Button } from "@/Components/UI/Button";
import { Api } from "@/Services/Api";
import { appConfig } from "@/Hooks/useAppConfig";

export const Moderation = () => {
  const [tab, setTab] = useState("settings");
  const [mutedUsers, setMutedUsers] = useState<any>([]);
  const [chatRateLimit, setChatRateLimit] = useState(
    appConfig.chatRatePerMinute.toString()
  );

  useEffect(() => queryMutedUsers, []);

  function queryMutedUsers() {
    Api.getMutedUsers().then((users) => setMutedUsers(users));
  }

  async function unmuteUser(id: string) {
    await Api.unmuteUser(id);
    queryMutedUsers();
  }

  const [updateChatRateLimit] = useMutation(UPDATE_CHAT_RATE_LIMIT);
  async function updateSettings() {
    await updateChatRateLimit({
      variables: { appConfigId: appConfig.id, rate: parseInt(chatRateLimit) },
    });

    toast.success("Paramètres sauvegardés");
  }

  return (
    <div className="absolute h-full flex flex-col justify-center left-[160px] z-[2]">
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-8 rounded-2xl flex flex-col bg-gray-primary overflow-y-auto h-[70vh] w-[950px] shadow-3xl"
      >
        <div className="flex items-end justify-between gap-x-8 border-b-2 pb-8 border-[#505050]">
          <span className="text-2xl font-condensed uppercase font-bold">
            Modération
          </span>
          <Tabs
            items={[
              { label: "Paramètres", value: "settings" },
              { label: "Ban list", value: "muted" },
            ]}
            onChange={(newTab) => setTab(newTab)}
          />
        </div>

        {tab === "settings" && (
          <div className="grid grid-cols-2 mt-8">
            <Input
              label="Nombre de messages max par minute dans le chat :"
              type="number"
              placeholder="10"
              value={chatRateLimit}
              onChange={setChatRateLimit}
            />

            <div className="col-span-1 col-start-1 mt-8">
              <Button onClick={() => updateSettings()}>Sauvegarder</Button>
            </div>
          </div>
        )}

        {tab === "muted" && (
          <div className="mt-8">
            <div className="flex  flex-col gap-y-4">
              {mutedUsers.map((user: any) => (
                <div
                  className="flex items-center justify-between"
                  key={user.id}
                >
                  <span>
                    {user.first_name} {user.last_name}
                  </span>
                  <Button onClick={() => unmuteUser(user.id)}>Unmute</Button>
                </div>
              ))}
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};
