import axios from "axios";

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("rxs:api-token");
  if (token) {
    config.headers = config.headers || {};
    // @ts-expect-error osef
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export class Api {
  static client = client;

  static async checkToken() {
    const response = await this.client.get("/auth/check");
    return response.data.authenticated;
  }

  static async getMe() {
    const response = await this.client.get("/auth/me");
    return response.data;
  }

  static async sendChatMessage(eventId: string, message: string, role: string) {
    const response = await this.client.post<
      | { message: string; messageLeft: number }
      | { error: string; message: string }
    >("/rxs/chat/send", {
      eventId,
      message,
      role,
    });

    return response.data;
  }

  static async muteUser(userId: string) {
    const response = await this.client.post("/rxs/chat/mute", { userId });
    return response.data;
  }

  static async unmuteUser(userId: string) {
    const response = await this.client.post("/rxs/chat/unmute", { userId });
    return response.data;
  }

  static async getMutedUsers() {
    const response = await this.client.get("/rxs/chat/muted");
    return response.data;
  }

  static async insertVote(pollId: string, optionId: string) {
    const response = await this.client.post("/rxs/polls/vote", {
      pollId,
      optionId,
    });

    return response.data;
  }
}
