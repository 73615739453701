// @unocss-include

export const sidebarItems = [
  // {
  //   title: "Produits",
  //   id: "products" as const,
  //   icon: 'i-carbon-tag',
  // },
  {
    title: "Chat",
    id: "chat" as const,
    icon: "i-jam-message-f",
  },
  // {
  //   title: "3D",
  //   id: "3d" as const,
  //   icon: 'i-carbon-cube',
  // },
  {
    title: "Sondages",
    id: "polls" as const,
    icon: "i-fluent-poll-16-filled",
  },
  // {
  //   title: "Utilisateurs",
  //   id: "users" as const,
  //   icon: 'i-carbon-user-multiple',
  // },
  {
    title: "Librarie",
    id: "library" as const,
    icon: "i-fluent-image-library-28-filled",
    roles: ["admin"],
  },
  {
    title: "Modération",
    id: "moderation" as const,
    icon: "i-material-symbols-remove-moderator",
    roles: ["admin"],
  },
];
