export const SmallLogo = (props: { className?: string }) => {
  return (
    <svg
      className={props.className}
      width="112"
      height="39"
      viewBox="0 0 112 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.2744 2.54022C95.4527 1.56644 95.4193 1.59687 94.0044 3.14883C92.2161 5.1116 90.4338 7.08046 88.6091 9.09193C88.9552 9.42058 89.2558 9.70054 89.5624 9.98659C89.8691 10.2726 90.1697 10.5343 90.5158 10.8539C90.7314 10.6043 90.868 10.4248 91.0229 10.2635C92.2373 9.00368 92.21 9.07062 93.8314 9.60925C95.9172 10.3061 98.0456 10.8812 100.277 11.5416C100.277 12.8592 100.207 14.3138 100.302 15.7623C100.365 16.7422 99.9979 17.2838 99.1751 17.7464C95.647 19.7518 92.1645 21.8332 88.6334 23.8325C87.9229 24.2342 87.8925 24.505 88.3145 25.1654C90.3124 28.2784 92.2616 31.431 93.4457 34.9671C93.634 35.5179 93.8101 36.0717 94.0044 36.656C95.2007 35.9591 96.3119 35.2683 97.4657 34.6506C100.429 33.0651 103.523 31.8601 106.939 31.8084C107.701 31.8084 108.457 32.0518 109.316 32.1979C109.252 31.8936 109.176 31.4645 109.055 31.0324C108.751 29.9338 108.144 28.8444 108.144 27.7428C108.053 21.8819 108.099 16.021 108.111 10.157C108.106 10.0241 108.127 9.8916 108.174 9.76723C108.221 9.64286 108.293 9.52914 108.384 9.43275C110.03 8.34029 110.858 6.5814 112 5.07813V4.90772C106.832 5.72022 101.917 5.00814 97.2744 2.54022ZM100.262 29.3191C100.262 29.5626 100.056 29.806 99.9584 30.0495C99.773 29.8825 99.6097 29.6922 99.4726 29.4835C98.4646 27.3533 97.4748 25.1806 96.4212 22.9013L100.195 20.4273C100.229 20.6411 100.253 20.8564 100.265 21.0725C100.28 23.8142 100.286 26.5591 100.262 29.3039V29.3191Z"
        fill="white"
      />
      <path
        d="M52.6328 29.5565C52.6328 24.2555 52.6025 18.9545 52.6328 13.6505C52.6511 11.4412 52.2138 9.16196 53.4344 7.08355C53.6834 6.66056 53.4526 6.3593 53.0093 6.08238C50.5986 4.56085 48.2182 2.99672 45.8287 1.44171C45.5919 1.25609 45.3581 1.07655 45.1941 0.951782C43.7216 1.89209 42.3978 2.82022 40.989 3.62967C39.735 4.35697 38.4173 4.98079 37.0965 5.58332C36.9369 5.61746 36.7719 5.61805 36.612 5.58505C36.452 5.55205 36.3007 5.48619 36.1675 5.39161C34.5279 4.29915 32.9157 3.13061 31.2731 2.02294C31.1712 1.97036 31.0583 1.94292 30.9437 1.94292C30.8291 1.94292 30.7161 1.97036 30.6142 2.02294C28.5648 3.54447 26.5366 5.066 24.4082 6.6636C25.4036 7.03246 26.2639 7.69537 26.8754 8.56471C27.4869 9.43405 27.8209 10.4689 27.8331 11.5325C27.8331 17.6186 27.8331 23.7047 27.8331 29.7909C27.8331 30.0069 27.8057 30.226 27.7814 30.5364H25.0306L24.9395 30.7342C25.1295 30.9311 25.3324 31.1151 25.5468 31.285C26.6702 32.0701 27.8422 32.8065 28.9231 33.6312C30.3288 34.7206 31.5706 35.9713 32.1505 38.0528C33.9996 34.5837 36.8172 32.3896 39.8686 30.366H37.0419C37.0046 30.2385 36.9772 30.1082 36.9599 29.9765C36.9599 23.4116 36.9599 16.8467 36.9599 10.2818C36.9601 10.0398 37.0562 9.80772 37.2271 9.63667C38.3839 8.72376 39.568 7.81084 40.7734 6.89792C41.5628 7.56739 42.2915 8.22165 43.0597 8.82113C43.2765 8.96894 43.4514 9.17052 43.5674 9.40624C43.6834 9.64196 43.7365 9.90377 43.7216 10.1662C43.7003 16.5353 43.7216 22.9014 43.7216 29.2705V30.1834L42.2035 30.2869L48.9044 37.3924L55.2015 30.6368H52.6389L52.6328 29.5565Z"
        fill="white"
      />
      <path
        d="M84.2242 29.5566C84.2242 24.1978 84.1969 18.839 84.2242 13.4801C84.2394 11.3256 83.8174 9.10421 85.0197 7.0897C85.2626 6.68193 85.0653 6.35633 84.6098 6.07636C82.4632 4.76785 80.3591 3.41064 78.2338 2.0504C77.6994 1.70957 77.2136 1.28963 76.7703 0.954895C75.3706 1.86781 74.0833 2.75639 72.7291 3.53845C71.4235 4.29313 70.0603 4.94435 68.694 5.58643C68.5478 5.63064 68.3938 5.64292 68.2424 5.62245C68.0911 5.60198 67.9458 5.54923 67.8165 5.46775C66.177 4.35399 64.5769 3.18241 62.9282 2.08083C62.7797 2.02459 62.6201 2.00356 62.4621 2.0194C62.3041 2.03524 62.1519 2.08751 62.0173 2.17212C60.4992 3.26458 59.0024 4.40877 57.5055 5.53774C57.0197 5.89987 56.54 6.26808 56.0117 6.66672C57.0133 7.0438 57.876 7.71859 58.4843 8.60073C59.0926 9.48288 59.4174 10.5302 59.4153 11.6026C59.4153 17.6339 59.4153 23.6652 59.4153 29.6966V30.6095H56.5765L56.4793 30.8195C59.2271 32.8005 62.4455 34.3007 63.7662 37.9615C65.6335 34.6142 68.3843 32.3897 71.5298 30.576L71.4569 30.3752H68.6454C68.6077 30.2881 68.5792 30.1974 68.5604 30.1044C68.5604 23.5111 68.5604 16.9178 68.5604 10.3245C68.5581 10.2037 68.5797 10.0837 68.624 9.97143C68.6683 9.85912 68.7344 9.75674 68.8185 9.67022C69.9722 8.7573 71.1564 7.84438 72.3769 6.93146C73.1724 7.57659 73.9254 8.25519 74.7148 8.86381C74.9176 9.00752 75.08 9.20119 75.1863 9.42613C75.2927 9.65106 75.3394 9.89967 75.322 10.148C75.3038 16.5384 75.322 22.9288 75.3069 29.3345C75.3069 29.6053 75.2826 29.8761 75.2674 30.1896L73.7827 30.2778C76.0568 32.6879 78.2793 35.0524 80.4866 37.3955L86.7686 30.6521H84.2242V29.5566Z"
        fill="white"
      />
      <path
        d="M20.0542 6.49932C19.2041 4.8439 18.6059 3.06066 17.8772 1.28656C17.6738 1.40828 17.44 1.54522 17.2123 1.68824C14.4797 3.41974 11.6348 4.93519 8.45586 5.64422C6.55822 6.06721 4.61505 6.6393 2.5656 5.46772C2.67069 5.99274 2.81377 6.5094 2.99371 7.0136C3.61893 8.42465 3.92977 9.95534 3.90457 11.4991C3.84992 16.8001 3.87421 22.1011 3.90457 27.4051C3.90457 28.0138 3.77098 28.452 3.24268 28.8141C1.76708 29.8244 1.02624 31.4098 0 32.9192C1.37237 32.8248 2.58989 32.6575 3.80438 32.6696C8.0824 32.7153 12.1145 33.7256 15.8035 35.9531C16.3652 36.2909 16.6961 36.2087 17.1303 35.7218C19.0401 33.5582 20.9985 31.4372 22.9356 29.2979C23.0783 29.1397 23.1997 28.9601 23.3424 28.7745L21.5571 27.1221C21.0227 27.6395 20.5886 28.1203 20.0906 28.5189C19.9728 28.6072 19.8382 28.6703 19.6951 28.7043C19.552 28.7383 19.4035 28.7425 19.2587 28.7167C16.9269 28.0503 14.6103 27.3169 12.2754 26.6565C11.8261 26.5318 11.6894 26.3766 11.6985 25.9353C11.7198 24.6755 11.7744 23.4066 11.6834 22.1498C11.6105 21.176 11.9627 20.6282 12.7946 20.1596C16.35 18.1451 19.8629 16.0545 23.4214 14.0309C24.0468 13.6749 24.1045 13.4223 23.725 12.8137C22.4467 10.7444 21.1654 8.66598 20.0542 6.49932ZM11.7835 17.4878C11.7476 17.3622 11.7212 17.234 11.7046 17.1044C11.7046 14.2439 11.7046 11.3804 11.7046 8.51991C11.7046 8.30081 11.9141 8.08475 12.0264 7.8687C12.1661 8.00259 12.3635 8.11519 12.4424 8.27951C13.4717 10.4857 14.4827 12.698 15.5545 15.029L11.7835 17.4878Z"
        fill="white"
      />
    </svg>
  );
};
