import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import { useMediaSelector } from "./Hooks/useMediaSelector";
import type { Media, MediaType } from "@/Contracts";
import { Bomp } from "@/Components/UI/Bomp";

export interface Props {
  filterMediaTypes?: MediaType[];
  onExpand: () => void;
  onMediaSelected?: (media: Media) => void;
}

export const InlineMediaSelector = (props: Props) => {
  const { medias: baseMedias, getIcon, setNewMedia } = useMediaSelector();

  const medias = useMemo(() => {
    if (!baseMedias) return [];

    return baseMedias
      .filter((media) => props.filterMediaTypes?.includes(media.type) ?? true)
      .sort((a) => {
        if (a.name.includes("PLK")) return -1;
        return 0.5 - Math.random();
      })
      .slice(0, 5);
  }, [baseMedias, props.filterMediaTypes]);

  return (
    <AnimatePresence>
      <div className="absolute h-full flex flex-col top-85 left-[160px] z-[2]">
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
        >
          <div className="w-[350px] flex">
            <div className="flex flex-col h-full">
              {medias.map((media) => (
                <div key={media.id} className="flex items-center space-x-4">
                  <Bomp
                    onClick={() => {
                      setNewMedia(media);
                      props.onMediaSelected?.(media);
                    }}
                    icon={getIcon(media)}
                    iconClassName="text-xl"
                  />

                  <span>{media.name}</span>
                </div>
              ))}

              <div className="inline-flex">
                <Bomp
                  onClick={() => props.onExpand()}
                  icon="i-carbon-overflow-menu-horizontal"
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
