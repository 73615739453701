import { useQuery } from "@apollo/client";
import type { Media } from "@/Contracts";
import { MediaType } from "@/Contracts";
import { useUpdateMediaStreamed } from "@/Features/Room/Api/updateMediaStreamed";
import { GET_MEDIAS } from "@/Gql/medias";
import { useStore } from "@/Store/index";

export const useMediaSelector = () => {
  const event = useStore((state) => state.event);

  const { data } = useQuery<{ medias: Media[] }>(GET_MEDIAS, {
    variables: { applicationId: event?.application_id },
  });

  /**
   * Get the media type icon
   */
  const getIcon = (media: Media) => {
    return {
      [MediaType.Image]: "i-fa6-solid-image",
      [MediaType.Video]: "i-fa6-solid-video",
      [MediaType.Pdf]: "i-fa6-solid-book",
      [MediaType.Quiz]: "i-fa6-solid-gamepad",
      [MediaType.ThreeD]: "i-fa6-solid-cube",
    }[media.type];
  };

  /**
   * Set a new media as streamed
   */
  const [updateMediaStreamedMutation] = useUpdateMediaStreamed();
  const setNewMedia = (media: Media) => {
    return updateMediaStreamedMutation({
      variables: { mediaId: media.id, eventId: event!.id },
    });
  };

  return {
    medias: data?.medias ?? [],
    getIcon,
    setNewMedia,
  };
};
