import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { HASURA_URL, HASURA_WEBSOCKET_URL } from "@/Config";

const wsLink = new GraphQLWsLink(
  createClient({
    url: HASURA_WEBSOCKET_URL,
    shouldRetry: () => true,
    retryAttempts: 10000,
  })
);
const httpLink = new HttpLink({ uri: HASURA_URL });

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("rxs:hasura-token");
  const headers = token ? { authorization: `Bearer ${token}` } : {};

  operation.setContext({ headers });

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, splitLink]),
  cache: new InMemoryCache(),
});
