import type { Media } from "@/Contracts";
import { getS3Path } from "@/Utils";

interface Props {
  media: Media;
}

export const Video = (props: Props) => {
  return (
    <div className="pointer-events-auto rounded-xl overflow-hidden w-full h-full">
      <video
        autoPlay
        src={getS3Path(props.media.url)}
        className="
          object-cover
          flex-1
          rounded-xl
          h-full
          absolute
          inset-0
          mx-auto
          md:rounded-[30px]
          md:object-contain
          md:w-auto
        "
        controls
        muted
        disablePictureInPicture
        controlsList="nofullscreen"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
