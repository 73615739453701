import { useEffect } from "react";
import { io } from "socket.io-client";
import { FloatingEmoji } from "./FloatingEmoji";
import { appConfig } from "@/Hooks/useAppConfig";

let rafId: number | null = null;
const floatingEmojis: FloatingEmoji[] = [];

const socket = io("https://plk-ws.renaissance.land");

const emojiMap = {
  "🔥": "fire",
  "🐻": "bear",
  "🌪️": "tornado",
  "🦉": "owl",
} as Record<string, string>;

socket.on("emoji", (data) => {
  const emoji = Object.entries(emojiMap).find(
    ([_, value]) => value === data
  )?.[0];

  if (!emoji) return;
  if (document.hidden) return;

  if (floatingEmojis.length < appConfig.maxDisplayedEmojis) {
    floatingEmojis.push(new FloatingEmoji(emoji));
  }
});
export const useReactions = () => {
  const loop = () => {
    rafId = requestAnimationFrame(loop);
    let i = 0;

    // Remove dead particles
    for (i = 0; i < floatingEmojis.length; ++i) {
      if (floatingEmojis[i].done) {
        floatingEmojis[i].flush();
        floatingEmojis.splice(i, 1);
      }
    }

    // Animate particles
    for (const emoji of floatingEmojis) {
      emoji.update();
      emoji.draw();
    }
  };

  useEffect(() => {
    loop();

    return () => {
      for (let i = 0; i < floatingEmojis.length; ++i) {
        floatingEmojis[i].flush();
      }
      if (rafId) cancelAnimationFrame(rafId);
    };
  }, []);

  let lastSend = 0;
  const sendReaction = async (emoji: string) => {
    if (Date.now() - lastSend > appConfig.emojisSendThrottle) {
      socket.emit("send-emoji", emojiMap[emoji]);
      lastSend = Date.now();
    } else {
      floatingEmojis.push(new FloatingEmoji(emoji));
    }
  };

  return { sendReaction };
};
