import { Canvas } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
  Stage,
  useGLTF,
} from "@react-three/drei";
import { Suspense, useEffect, useRef } from "react";
import { DataPacket_Kind, RoomEvent } from "livekit-client";
import { gsap } from "gsap";
import type { PerspectiveCamera as PerspectiveCameraThree } from "three";
import type { Media } from "@/Contracts";
import { getS3Path } from "@/Utils";
import { useStore } from "@/Store";
import { useUser } from "@/Hooks/useUser";

interface Props {
  media: Media;
}

function Scene({ url }: { url: string }) {
  const { scene } = useGLTF(url, true);
  return (
    <Suspense fallback={null}>
      <Stage
        environment={null}
        intensity={1}
        position={[0, 0, 0]}
        contactShadow={false}
        shadowBias={-0.0015}
      >
        <primitive object={scene} />
      </Stage>
    </Suspense>
  );
}

export const ThreeD = (props: Props) => {
  const room = useStore((state) => state.room);
  const decoder = new TextDecoder();
  const encoder = new TextEncoder();
  const BROADCAST_RATE = 200;
  let broadcastInterval: number;
  const camera = useRef<PerspectiveCameraThree | null>();
  const { roles } = useUser();

  const handleDataReceived = (payload: any) => {
    const { type, data } = JSON.parse(decoder.decode(payload));

    if (type === "camera-movement" && camera.current) {
      gsap.to(camera.current.position, { duration: 0.3, ...data.position });
      gsap.to(camera.current.rotation, { duration: 0.3, ...data.rotation });
    }
  };

  useEffect(() => {
    if (!room) return;

    room.on(RoomEvent.DataReceived, handleDataReceived);
    if (!roles.includes("admin")) return;

    // @ts-expect-error osef
    broadcastInterval = setInterval(async () => {
      const currentCam = camera.current;

      if (!currentCam) return;

      const { x, y, z } = currentCam.rotation;
      const strData = JSON.stringify({
        type: "camera-movement",
        data: {
          position: currentCam.position,
          rotation: { x, y, z },
        },
      });

      room.localParticipant.publishData(
        encoder.encode(strData),
        DataPacket_Kind.RELIABLE
      );
    }, BROADCAST_RATE);

    return () => {
      clearInterval(broadcastInterval);
      room.off(RoomEvent.DataReceived, handleDataReceived);
    };
  }, [room]);

  if (!room) return <div>Loading ...</div>;

  return (
    <Canvas className="rounded-lg">
      <PerspectiveCamera ref={camera} position={[2, 2, 2]} makeDefault />
      {roles.includes("admin") ? <OrbitControls /> : null}

      <Scene url={getS3Path(props.media.url)} />
      <Environment preset="forest" />
    </Canvas>
  );
};
