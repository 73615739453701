import { useUpdateMediaStreamed } from "../Room/Api/updateMediaStreamed";
import { useUser } from "@/Hooks/useUser";
import { useStore } from "@/Store";

export const RemoveIcon = () => {
  const { roles } = useUser();
  const [updateMediaStreamedMutation] = useUpdateMediaStreamed();
  const event = useStore((state) => state.event);

  if (!roles.includes("admin")) {
    return <></>;
  }

  function handleClick() {
    return updateMediaStreamedMutation({
      variables: { eventId: event!.id, mediaId: null },
    });
  }

  return (
    <div
      onClick={handleClick}
      className="z-100 cursor-pointer absolute bottom-0 pointer-events-auto left-50px z-10 flex justify-center items-center"
    >
      <i className="i-carbon-close-outline text-red text-2xl"></i>
    </div>
  );
};
