import { useState } from "react";
import { InlineAdminPoll } from "./AdminPollInline";
import { ExpandedAdminPoll } from "./AdminPollExpanded";
import { AdminSelectedPoll } from "./AdminSelectedPoll";
import type { Poll } from "@/Contracts";

export const AdminPoll = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState<Poll | null>(null);

  if (selectedPoll) {
    return <AdminSelectedPoll poll={selectedPoll} />;
  }

  if (!isExpanded) {
    return (
      <InlineAdminPoll
        onExpand={() => setIsExpanded(true)}
        onPollSelect={(poll) => setSelectedPoll(poll)}
      />
    );
  }

  return <ExpandedAdminPoll onPollSelect={(poll) => setSelectedPoll(poll)} />;
};
