import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { IconValid } from "../../Components/Icons/IconValid";
import type { Activity, Media } from "@/Contracts";
import { client as LmsClient } from "@/Services/LmsApolloClient";
import { GET_ACTIVITY_BY_PK } from "@/Gql/Lms";
import { classes } from "@/Utils";

interface Props {
  media: Media;
}

export type QuizzState = "PENDING" | "FINISHED";

export const Quizz = (props: Props) => {
  const [quizzState, setQuizzState] = useState<QuizzState>("PENDING");
  const { data, loading } = useQuery<{ activity_by_pk: Activity }>(
    GET_ACTIVITY_BY_PK,
    {
      client: LmsClient,
      variables: { id: props.media.url },
    }
  );

  useEffect(() => {
    setQuizzState("PENDING");
  }, [props.media]);

  if (loading) return <div>Chargement du quizz...</div>;

  return (
    <div className="w-1/2 mx-auto relative">
      {data?.activity_by_pk.questions.map((question, index) => (
        <div key={index}>
          <h1 className="text-lg font-bold my-4">
            {index + 1}. {question.text}
          </h1>
          {question.answers.map((answer, index) => (
            <div
              className="flex items-center space-x-4 text-sm bg-gray-400 rounded-lg py-2 px-4 my-1"
              key={index}
            >
              <label>
                <input
                  disabled={quizzState === "FINISHED"}
                  className="radio radio-secondary"
                  type="radio"
                  name={question.text}
                  value={answer.text}
                />
              </label>
              <span
                className={classes({
                  "text-green-400":
                    quizzState === "FINISHED" && answer.is_correct,
                  "text-red-400":
                    quizzState === "FINISHED" && !answer.is_correct,
                })}
              >
                {answer.text}
              </span>
            </div>
          ))}

          {quizzState === "FINISHED" && (
            <div className="text-medium">Correction: {question.correction}</div>
          )}
        </div>
      ))}
      <div
        className="float-right bg-green-500 h-6 w-6 flex justify-center items-center rounded-full p-1 hover:cursor-pointer hover:bg-green-600"
        onClick={() => setQuizzState("FINISHED")}
      >
        <IconValid />
      </div>
    </div>
  );
};
