import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { motion } from "framer-motion";
import type { Poll } from "@/Contracts";
import { Bomp } from "@/Components/UI/Bomp";
import { GET_POLLS } from "@/Gql/poll";
import { useStore } from "@/Store";

interface Props {
  onExpand: () => void;
  onPollSelect: (poll: Poll) => void;
}

export const InlineAdminPoll = (props: Props) => {
  const event = useStore((state) => state.event);

  const { data, loading } = useQuery<{ polls: Poll[] }>(GET_POLLS, {
    variables: { eventId: event!.id },
  });

  const polls = useMemo(() => {
    if (!data) return [];

    return data.polls.slice(0, 3);
  }, [data]);

  if (loading) return <div>Loading...</div>;

  const getVotesCount = (poll: Poll) => {
    return poll.options.reduce((acc, option) => acc + option.votesCount, 0);
  };

  return (
    <motion.div
      className="font-sans absolute h-full flex flex-col top-56 left-[160px] z-[2]"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
    >
      {polls.map((poll) => (
        <Bomp
          className="!items-start"
          key={poll.id}
          onClick={() => props.onPollSelect(poll)}
        >
          <p className="font-bold">{poll.title}</p>
          <div className="flex space-x-2 mt-2 text-xs">
            <p>{new Date(poll.createdAt).toLocaleDateString()}</p>
            <span>-</span>
            <p className="italic">{getVotesCount(poll)} votes</p>
          </div>
        </Bomp>
      ))}
      <div className="inline-flex ">
        <Bomp
          onClick={() => props.onExpand()}
          icon="i-carbon-overflow-menu-horizontal"
        />
      </div>
    </motion.div>
  );
};
