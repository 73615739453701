import { gql } from "@apollo/client";

export const GET_ACTIVITY_BY_PK = gql`
  query GetActivityByPk($id: uuid!) {
    activity_by_pk(id: $id) {
      instruction
      title
      questions {
        correction
        id
        text
        answers {
          is_correct
          text
        }
      }
    }
  }
`;
