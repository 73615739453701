import { gql } from "@apollo/client";

/**
 * Full Poll Fragment
 */
export const FULL_POLL_FRAGMENT = gql`
  fragment PollFragment on polls {
    id
    title
    type
    closedAt: closed_at
    createdAt: created_at
    options: poll_options {
      pollId: poll_id
      title
      id
      votesCount: votes_count
    }
  }
`;

/**
 * Insert the given poll into the database.
 */
export const INSERT_POLL = gql`
  mutation InsertPoll(
    $title: String!
    $pollOptions: [poll_options_insert_input!]!
    $eventId: uuid!
    $type: String!
  ) {
    insert_polls_one(
      object: {
        poll_options: { data: $pollOptions }
        title: $title
        type: $type
        event_id: $eventId
      }
    ) {
      id
    }
  }
`;

/**
 * Insert the given vote for the poll
 */
export const INSERT_VOTE = gql`
  mutation InsertVote($userUuid: uuid!, $pollId: uuid!, $optionId: uuid!) {
    insert_poll_user_votes_one(
      object: { user_uuid: $userUuid, poll_id: $pollId, option_id: $optionId }
    ) {
      id
    }

    update_poll_options_by_pk(
      pk_columns: { id: $optionId }
      _inc: { votes_count: 1 }
    ) {
      id
    }
  }
`;

/**
 * Subscribe to new votes
 */
export const VOTE_AGGREGATE_SUBSCRIPTION = gql`
  subscription MySubscription($pollId: uuid!) {
    polls_by_pk(id: $pollId) {
      poll_options {
        id
        poll_user_votes_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

/**
 * Get all polls
 */

export const GET_POLLS = gql`
  ${FULL_POLL_FRAGMENT}
  query GetPolls($eventId: uuid!) {
    polls(
      order_by: { created_at: desc }
      where: { event_id: { _eq: $eventId } }
    ) {
      ...PollFragment
    }
  }
`;
