import { useEffect, useState } from "react";
import { debounce } from "ts-debounce";
import { useNavigate } from "react-router-dom";
import { Header } from "../Components/UI/Header";
import type { SidebarItemId } from "@/Components/Sidebar/index";
import { StageRenderer } from "@/Components/StageRenderer";
import { useStore } from "@/Store";
import { Chat } from "@/Features/Chat";
import { Sidebar } from "@/Components/Sidebar/index";
import { Poll } from "@/Features/Poll/Poll";
import { useApp } from "@/Hooks/useApp";
import { AdminPoll } from "@/Features/Poll/AdminPoll";
import { MediaSelector } from "@/Features/MediaSelector";
import { useChat } from "@/Features/Chat/Hooks/useChat";
import { Message } from "@/Features/Chat/Message";
import { useUser } from "@/Hooks/useUser";
import { Moderation } from "@/Features/Moderation/index";
import { isLandscapeMobile, isSm } from "@/Utils";
import { Api } from "@/Services/Api";
import EosIconsLoading from "~icons/eos-icons/loading";
import { Disconnecter } from "@/Services/Disconnecter";

export const RoomPage = () => {
  const [selectedSidebarItem, setSelectedSidebarItem] = useState<
    SidebarItemId | undefined
  >();

  const { event, isTokenExpired } = useApp();
  const { roles } = useUser();

  const { useSubscribeChatMessage, latestMessage } = useChat();

  useEffect(() => {
    if (isSm() || isLandscapeMobile()) return;

    if (event?.poll) {
      setSelectedSidebarItem("polls");
    }
  }, [event?.poll?.id]);

  const [loading, setLoading] = useState(true);
  const setUser = useStore((state) => state.setUser);

  useEffect(() => {
    Api.getMe()
      .then((result) => {
        setUser(result);
        setLoading(false);

        Disconnecter.start(result.id);
      })
      .catch(() => {
        window.location.href = "/";
      });

    // const interval = setInterval(() => {
    //   Api.checkToken().then((result: any) => {
    //     console.log(result);
    //     if (!result) {
    //       // window.location.href = "/";
    //     }
    //   });
    // }, 10000);

    return () => {
      // clearInterval(interval);
    };
  }, []);

  useSubscribeChatMessage();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 200);

    // @ts-expect-error osef
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      // @ts-expect-error osef
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  const navigate = useNavigate();

  if (!event || loading) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <EosIconsLoading className="text-4xl" />
        <p className="mt-4 font-condensed text-lg uppercase">
          Chargement de la plateforme...
        </p>
      </div>
    );
  }

  if (isTokenExpired()) {
    navigate({ pathname: "/" });
  }

  return (
    <div id="room-page" className="c-room-page">
      {!isSm() && !isLandscapeMobile() && <Header />}

      <Sidebar
        selected={selectedSidebarItem}
        onItemClick={(item) => {
          if (item === selectedSidebarItem) {
            return setSelectedSidebarItem(undefined);
          }

          setSelectedSidebarItem(item);
        }}
      />
      {selectedSidebarItem === "chat" ? (
        // TODO: Remove hardcoded pixel values
        <div className="absolute left-[160px] z-[2]">
          <Chat />
        </div>
      ) : null}

      {!selectedSidebarItem && latestMessage ? (
        <div className="hidden c-overlay-messages absolute left-[160px] z-[2] text-black top-[100px] md:block">
          <div className="z-10 relative">
            <div className="z-10 relative">
              <Message
                admin-controls={false}
                type="inline"
                message={latestMessage}
              />
            </div>
            <div className="h-full w-full opacity-75 scale-95 bg-white z-1 absolute top-3 rounded-lg"></div>
            <div className="h-full w-full opacity-50 scale-90 bg-white z-1 absolute top-6 rounded-lg"></div>
          </div>
        </div>
      ) : null}

      {selectedSidebarItem === "moderation" && <Moderation />}

      {selectedSidebarItem === "polls" && (
        <>
          {roles.includes("viewer") ? (
            <div className="absolute h-full flex flex-col top-50 left-[160px] z-[2]">
              {event.poll ? (
                <Poll poll={event.poll} />
              ) : (
                <div className="mt-8 bg-gray-primary p-4 rounded-xl">
                  Pas de sondages pour le moment.
                </div>
              )}
            </div>
          ) : (
            <AdminPoll />
          )}
        </>
      )}

      {selectedSidebarItem === "library" && (
        <MediaSelector
          onMediaSelected={() => setSelectedSidebarItem(undefined)}
        />
      )}

      <div className="flex flex-1">
        <StageRenderer />
        {/* <LiveKitRoom
          stageRenderer={StageRenderer}
          url={url!}
          token={token!}
          connectOptions={{ autoSubscribe: true }}
          onConnected={(room) => {
            onConnected(room, searchParams);
            setRoom(room);
          }}
          roomOptions={{
            videoCaptureDefaults: { resolution: VideoPresets.h1080.resolution },
          }}
        /> */}
      </div>
    </div>
  );
};
