import "./index.css";
import { useReactions } from "./useReactions";
import { appConfig } from "@/Hooks/useAppConfig";

export const Reactions = ({ className }: { className?: string }) => {
  const { sendReaction } = useReactions();

  return (
    <div
      className={`hidden sm:flex text-2xl pointer-events-auto select-none	space-x-4 ${className}`}
    >
      {appConfig.emojis.map((emoji) => (
        <div
          key={emoji}
          className="cursor-pointer c-reactions__emoji"
          onClick={() => sendReaction(emoji)}
        >
          {emoji}
        </div>
      ))}
    </div>
  );
};
