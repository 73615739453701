import { useSubscription } from "@apollo/client";
import type { Media } from "@/Contracts";
import { EVENT_MEDIA_SUBSCRIPTION } from "@/Gql/events";

export interface SubscribeMediaMessage {
  event_media: EventMedia[];
}

export interface EventMedia {
  id: string;
  media_id: string;
  event_id: string;
  position: any;
  media: Media;
}

export const useSubscribeMediaStreamed = (eventId: string) => {
  return useSubscription<SubscribeMediaMessage>(EVENT_MEDIA_SUBSCRIPTION, {
    variables: { eventId },
  });
};
