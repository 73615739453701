import { useMemo, useState } from "react";

export const usePagination = <T>(options: {
  items: T[];
  itemsPerPage: number;
}) => {
  const [pageIndex, setPageIndex] = useState(0);

  /**
   * The max page index
   */
  const maxPageIndex = useMemo(() => {
    return Math.ceil(options.items.length / options.itemsPerPage) - 1;
  }, [options.items.length, options.itemsPerPage]);

  /**
   * Go to the next page
   */
  const goToNextPage = () => {
    return setPageIndex((prev) => Math.min(prev + 1, maxPageIndex));
  };

  /**
   * Go to the previous page
   */
  const goToPreviousPage = () => {
    return setPageIndex((prev) => Math.max(prev - 1, 0));
  };

  /**
   * Paginated items
   */
  const items = useMemo<T[]>(() => {
    if (!options.items.length) {
      return [];
    }

    return options.items.slice(
      pageIndex * options.itemsPerPage,
      pageIndex * options.itemsPerPage + options.itemsPerPage
    );
  }, [options.items, pageIndex, options.itemsPerPage]);

  return {
    items: items as T[],
    pageIndex,
    setPageIndex,
    maxPageIndex,
    goToNextPage,
    goToPreviousPage,
  };
};
