import "@unocss/reset/tailwind.css";
import "uno.css";
import "./Assets/Styles/index.css";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { StrictMode } from "react";
import { ToastContainer } from "react-toastify";
import App from "./App";
import { client } from "./Services/ApolloClient";
// import { initSentry } from "./Utils/sentry";
import "react-toastify/dist/ReactToastify.css";

// initSentry();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ApolloProvider client={client}>
      <App />
      <ToastContainer theme="dark" position="bottom-center" />
    </ApolloProvider>
  </StrictMode>
);
