import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { useAppConfig } from "./Hooks/useAppConfig";
import { RoomPage } from "./Views/RoomPage";
import { PreJoinPage } from "./Views/PreJoinPage";
import EosIconsLoading from "~icons/eos-icons/loading";

const App = () => {
  const { loading } = useAppConfig({ appTitle: "PLK", immediate: true });
  if (loading) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <EosIconsLoading className="text-4xl" />
        <p className="mt-4 font-condensed text-lg uppercase">
          Chargement de la plateforme...
        </p>
      </div>
    );
  }

  return (
    <div className="font-content">
      <Router>
        <Routes>
          <Route path="/room" element={<RoomPage />} />
          <Route path="/" element={<PreJoinPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
