import clsx from "clsx";
import { motion } from "framer-motion";
import { useMutation } from "@apollo/client";
import type { Poll } from "@/Contracts";
import { useStore } from "@/Store";
import { Button } from "@/Components/UI/Button";
import { SET_EVENT_POLL } from "@/Gql/events";

interface Props {
  poll: Poll;
}

export const AdminSelectedPoll = ({ poll }: Props) => {
  const event = useStore((state) => state.event);

  const isCurrentPoll = event?.poll?.id === poll.id;

  const [setEventPoll] = useMutation(SET_EVENT_POLL);

  const onStopPollClick = () => {
    setEventPoll({ variables: { eventId: event!.id, pollId: null } });
  };

  const onRelaunchPollClick = () => {
    setEventPoll({ variables: { eventId: event!.id, pollId: poll.id } });
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      className={clsx(
        "font-sans p-8 z-1 rounded-2xl bg-[#242424] w-[400px] shadow-3xl",
        "absolute flex flex-col top-50 left-[160px] z-[2]"
      )}
    >
      <p className="text-lg font-bold">{poll.title}</p>

      <div className="flex flex-col gap-4 mt-4">
        {[...poll.options]
          .sort((a, b) => b.votesCount - a.votesCount)
          .map((option) => (
            <div
              key={option.id}
              className={clsx(
                "flex items-center justify-between px-4 py-2",
                "bg-gray-focus rounded-lg"
              )}
            >
              <p>{option.title}</p>
              <p>{option.votesCount}</p>
            </div>
          ))}
      </div>

      <div className="border-t border-primary my-4 pt-4 grid grid-cols-2">
        <p className="font-bold">En cours ?</p>
        <p>{isCurrentPoll ? "Oui" : "Non"} </p>
        <p className="font-bold">Date de création</p>
        <p>{new Date(poll.createdAt).toLocaleDateString()} </p>
      </div>

      {isCurrentPoll ? (
        <div className="">
          <Button onClick={onStopPollClick}>Arrêter le sondage</Button>
        </div>
      ) : (
        <div className="">
          <Button onClick={onRelaunchPollClick}>Relancer le sondage</Button>
        </div>
      )}
    </motion.div>
  );
};
