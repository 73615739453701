import { motion } from "framer-motion";
import { usePinnedMessage } from "./Hooks/usePinnedMessage";
import { IconPin } from "@/Components/Icons/IconPin";
import { useUser } from "@/Hooks/useUser";

export const PinnedMessage = () => {
  const { useSubscription, useUnsetPinnedMessage } = usePinnedMessage();
  const { pinnedMessage } = useSubscription();
  const unsetPinnedMessage = useUnsetPinnedMessage();
  const { isAdmin } = useUser();

  if (!pinnedMessage) return null;

  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className="my-2 last:mb-0 flex flex items-end"
    >
      <div className="flex w-full md:w-[350px] c-chat-message">
        <div className="bg-white border-l-3 border-primary flex-1 text-black shadow-lg relative rounded-lg px-4 py-2 text-md">
          <div className="absolute text-primary right-2 flex items-center gap-2">
            {isAdmin ? (
              <i
                className="inline-block i-material-symbols-unpin text-base cursor-pointer"
                onClick={unsetPinnedMessage}
              />
            ) : (
              <IconPin />
            )}
          </div>

          <span className="block font-semibold">
            {pinnedMessage.username || "Utilisateur Anonyme"}
          </span>
          <span>{pinnedMessage.content}</span>
        </div>
      </div>
    </motion.div>
  );
};
