import { useMemo, useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useMediaSelector } from "./Hooks/useMediaSelector";
import type { Media } from "@/Contracts";
import { MediaType } from "@/Contracts";
import { Tabs } from "@/Components/UI/Tabs";
import { usePagination } from "@/Hooks/usePagination";
import { Button } from "@/Components/UI/Button";
import { Input } from "@/Components/UI/Input";
import { Pagination } from "@/Components/UI/Pagination";

interface Props {
  onMediaSelected?: (media: Media) => void;
}

export const ExpandedMediaSelector = (props: Props) => {
  const { medias, getIcon, setNewMedia } = useMediaSelector();

  const [category, setCategory] = useState<MediaType | null>(null);
  const [search, setSearch] = useState("");

  const filteredMedias = useMemo(() => {
    return medias
      ?.filter((media) => {
        if (!category) return true;
        return media.type === category;
      })
      .filter((media) => {
        if (!search.length) return true;
        return media.name.toLowerCase().includes(search.toLowerCase());
      });
  }, [category, medias, search]);

  const { items, goToNextPage, goToPreviousPage, pageIndex } = usePagination({
    items: filteredMedias,
    itemsPerPage: 24,
  });

  return (
    <div className="absolute h-full flex flex-col justify-center left-[160px] z-[2]">
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-8 rounded-2xl flex flex-col bg-gray-primary overflow-y-auto h-[70vh] w-[950px] shadow-3xl"
      >
        <div className="flex items-center justify-between gap-8">
          <div>
            <Button className="btn btn-sm btn-accent rounded-full">
              Upload media
            </Button>
          </div>
          <Tabs
            items={[
              { label: "Images", value: MediaType.Image },
              { label: "Videos", value: MediaType.Video },
              { label: "Documents", value: MediaType.Pdf },
              { label: "3D Files", value: MediaType.ThreeD },
            ]}
            onChange={(newTab) => setCategory(newTab as MediaType)}
          />

          <div>
            <Input
              size="sm"
              placeholder="Search"
              value={search}
              onChange={setSearch}
            />
          </div>
        </div>

        <div className="grid grid-cols-8 gap-8 my-8">
          {items.map((media, index) => (
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              initial={{ opacity: 0, y: 100 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: index * 0.01 },
              }}
              exit={{ opacity: 0, y: 100 }}
              key={media.id}
              className="flex flex-col cursor-pointer"
              onClick={() => {
                setNewMedia(media);
                props.onMediaSelected?.(media);
              }}
            >
              <div
                className={clsx(
                  "w-full aspect-square p-2 hover:bg-gray-400 transition rounded-2xl",
                  "bg-gray-focus flex justify-center items-center"
                )}
              >
                <i className={`${getIcon(media)} text-xl`}></i>
              </div>
              <span className="truncate text-xs mt-2 text-center">
                {media.name}
              </span>
            </motion.div>
          ))}
        </div>

        <Pagination
          onPrev={goToPreviousPage}
          onNext={goToNextPage}
          index={pageIndex + 1}
        />
      </motion.div>
    </div>
  );
};
