function createElementFromHTML(emoji: string): HTMLElement {
  const div = document.createElement("div");
  div.innerHTML = `<div class="absolute pointer-events-none">${emoji}</div>`;
  return div.firstChild as HTMLElement;
}

export class FloatingEmoji {
  private x: number;
  private y: number;
  private phase: number;
  private radius: number;
  private speed: number;
  private scale: number;
  private grow: number;
  private alpha: number;
  public done: boolean;

  private emoji: HTMLElement | null;

  constructor(emoji: string) {
    this.x = window.innerWidth * 0.7;
    this.y = window.innerHeight - 100;

    this.phase = Math.random() * 2;
    this.radius = Math.random() * 0.5;
    this.speed = (1.5 + Math.random() * 2) * 0.6;
    this.scale = 1.5 + Math.random() * 0.8;
    this.grow = 0.01;
    this.alpha = 1;
    this.done = false;

    this.emoji = createElementFromHTML(emoji);

    document.body.appendChild(this.emoji);
    this.draw();
  }

  flush() {
    if (document.body.contains(this.emoji)) {
      document.body.removeChild(this.emoji!);
    }
    this.emoji = null;
  }

  draw() {
    if (this.done) return;
    if (!this.emoji) return;
    this.emoji!.style.transform = `translateX( ${this.x}px ) translateY( ${this.y}px ) translateZ( 0 ) scale( ${this.grow} )`;

    this.emoji!.style.opacity = this.alpha.toString();
  }

  update() {
    this.alpha = this.alpha > 0 ? this.alpha - 0.0055 : this.alpha;
    this.alpha = this.alpha < 0 ? 0 : this.alpha;

    this.x += Math.cos(this.phase / 50) * this.radius;
    this.y -= this.speed;

    this.grow += (this.scale - this.grow) / 10;
    this.phase += 1;

    this.done = !!(this.y < -100 || this.alpha <= 0);
  }
}
