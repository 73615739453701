import { useStore } from "@/Store";

export const useUser = () => {
  const user = useStore((state) => state.user);

  const roles = [
    ...new Set(
      user?.wonAuctions.map((auction: any) => auction.item.slug) || []
    ),
  ];
  const userId = user?.id;

  const isAllowedToStream = false;
  const isAllowedToTalk = false;

  const isAdmin = user?.is_admin;
  const isViewer = !user?.is_admin;

  if (isAdmin) {
    roles.push("admin");
  } else if (isViewer) {
    roles.push("viewer");
  }

  const isOnlyIntern =
    roles.length === 2 && roles.includes("intern") && roles.includes("viewer");

  return {
    user,
    metadata: {},
    userId,
    roles,
    isAdmin,
    isAllowedToStream,
    isAllowedToTalk,
    isOnlyIntern,
  };
};
