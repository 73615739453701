import { gql } from "@apollo/client";

export const UPDATE_CHAT_RATE_LIMIT = gql`
  mutation UpdateChatRateLimit($appConfigId: uuid!, $rate: Int!) {
    update_application_config_by_pk(
      _append: { app_config: { chatRatePerMinute: $rate } }
      pk_columns: { id: $appConfigId }
    ) {
      app_config
    }
  }
`;
