import clsx from "clsx";

interface Props {
  className?: string;
  src: string;
}

export const Avatar = (props: Props) => {
  return (
    <div
      className={clsx(
        "shadow-3xl bg-primary rounded-full overflow-hidden",
        "w-12 h-12",
        props.className
      )}
    >
      <img src={props.src} />
    </div>
  );
};
