import clsx from "clsx";
import React from "react";

export interface Props {
  onClick?: () => void;
  isSelected?: boolean;
  badgeContent?: string;
  className?: string;
  icon?: string;
  iconClassName?: string;
}

export const Bomp = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      onClick={() => props.onClick?.()}
      className={clsx(
        props.className,
        "flex flex-col my-2 py-4 px-4 rounded-2xl items-center bg-gray-primary relative",
        "cursor-pointer border-transparent border-2 transition duration-300 ease-in-out",
        "hover:bg-gray-focus hover:scale-110 duration-100 hover:border-gray-primary",
        {
          "bg-gray-focus": props.isSelected,
        }
      )}
    >
      {props.badgeContent && (
        <div className="absolute bg-primary rounded-full text-xs font-bold px-2 py-1 -top-2 -right-2">
          {props.badgeContent}
        </div>
      )}

      {props.icon && (
        <div className={clsx("block", props.icon, props.iconClassName)} />
      )}

      {props.children}
    </div>
  );
};
