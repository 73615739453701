import { Track } from "livekit-client";
import type { Room, TrackPublication } from "livekit-client";
import { S3_URL } from "@/Config";

/**
 * Parse a JWT
 */
export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => {
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/**
 * Generate an UUID v4
 */
export const uuidv4 = () => {
  // @ts-expect-error we don't care
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const getS3Path = (fileName: string) => `${S3_URL}${fileName}`;

/**
 * See: https://github.com/livekit/client-sdk-js/issues/197
 */
export const getTrackMonkeyPatch = (room: Room) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (source: Track.Source): TrackPublication | undefined => {
    if (source === Track.Source.Unknown) {
      return;
    }

    for (const [, pub] of room.localParticipant.tracks) {
      if (pub.source === source) {
        return pub;
      }

      if (pub.source === Track.Source.Unknown) {
        if (
          source === Track.Source.Microphone &&
          pub.kind === Track.Kind.Audio &&
          pub.trackName !== "screen"
        ) {
          return pub;
        }

        if (
          source === Track.Source.Camera &&
          pub.kind === Track.Kind.Video &&
          pub.trackName !== "screen"
        ) {
          return pub;
        }

        if (
          source === Track.Source.ScreenShare &&
          pub.kind === Track.Kind.Video &&
          pub.trackName === "screen"
        ) {
          return pub;
        }

        if (
          source === Track.Source.ScreenShare &&
          pub.kind === Track.Kind.Video &&
          pub.track?.source === "screen_share"
        ) {
          return pub;
        }

        if (
          source === Track.Source.ScreenShareAudio &&
          pub.kind === Track.Kind.Audio &&
          pub.trackName === "screen"
        ) {
          return pub;
        }
      }
    }
  };
};

export const classes = (dynamic: Record<string, unknown>, classes = "") => {
  return Object.entries(dynamic)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(" ")
    .concat(" ")
    .concat(classes);
};

export const duplicate = <T extends any[]>(obj: T, count: number): T => {
  const result: any = [];

  for (let i = 0; i < count; i++) {
    result.push(...obj);
  }

  return result;
};

export const isSm = () => window.innerWidth < 768;
export const isLandscapeMobile = () =>
  screen?.orientation?.angle === 90 ||
  screen?.orientation?.angle === -90 ||
  window.orientation === 90 ||
  window.orientation === -90;
