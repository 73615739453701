import { Button } from "./Button";

interface Props {
  onPrev?: () => void;
  onNext?: () => void;
  index: number;
}

export const Pagination = (props: Props) => {
  return (
    <div className="flex justify-center mt-auto mt-8">
      <div className="btn-group">
        <Button
          className="bg-primary rounded-r-none !px-3"
          onClick={() => props.onPrev?.()}
        >
          <i className="inline-flex text-[8px] i-fa-chevron-left"></i>
        </Button>
        <Button className="btn btn-sm btn-accent !px-2 rounded-none">
          Page {props.index}
        </Button>
        <Button
          className="bg-primary rounded-l-none !px-3"
          onClick={() => props.onNext?.()}
        >
          <i className="inline-flex text-[8px] i-fa-chevron-right"></i>
        </Button>
      </div>
    </div>
  );
};
