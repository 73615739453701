import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { SmallLogo } from "../Components/Icons/SmallLogo";
import { Button } from "@/Components/UI/Button";
import { Input } from "@/Components/UI/Input";
import { RoleSelector } from "@/Components/RoleSelector";
import { Api } from "@/Services/Api";

async function login(email: string, password: string, eventId: string) {
  return await fetch(`${import.meta.env.VITE_API_URL}/auth/rxs-login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, eventId }),
  }).then((res) => res.json());
}

const event = "260551fe-777a-4046-876f-f23eef8b0b64";

export const PreJoinPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"login" | "select-role">("login");

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const [resetPassword, setResetPassword] = useState(false);
  const [resetEmailInput, setResetEmailInput] = useState("");

  const [loginError, setLoginError] = useState("");
  const [roles, setRoles] = useState<string[]>([]);

  const connectToRoom = async () => {
    setLoginError("");

    const resultLogin = await login(emailInput, passwordInput, event!);

    if (resultLogin.error === "E_UNAUTHORIZED_ACCESS") {
      setLoginError("Vous devez posséder un poste pour accéder au livestream.");
      return;
    }

    if (!resultLogin.auth) {
      setLoginError("Email ou mot de passe incorrect.");
      return;
    }

    setRoles(resultLogin.roles);
    localStorage.setItem("rxs:api-token", resultLogin.auth.token);
    localStorage.setItem("rxs:hasura-token", resultLogin.jwtToken);

    const roles = resultLogin.roles.filter(
      (role: string) => role !== "admin" && role !== "viewer"
    );

    if (roles.length === 1) {
      onRoleSelected(roles[0]);
      return;
    }

    if (roles.length === 0) {
      if (resultLogin.roles.includes("admin")) {
        onRoleSelected("boss");
        return;
      }

      alert("Vous n'avez pas de rôle dans cette room");
      return;
    }

    setStep("select-role");
  };

  function onRoleSelected(role: string) {
    localStorage.setItem("rxs:role", role);
    const params: Record<string, string> = { event, role };
    navigate({
      pathname: "/room",
      search: `?${new URLSearchParams(params).toString()}`,
    });
  }

  async function sendReset() {
    await Api.client
      .post("/auth/forgot-password", {
        email: resetEmailInput,
      })
      .catch(() => {});

    toast.success(
      "Si votre adresse email est correcte, vous recevrez un email avec un lien de réinitialisation."
    );
    setResetPassword(false);
  }

  return (
    <main className="h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <SmallLogo className="m-auto scale-150" />

        {resetPassword && (
          <motion.div
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="absolute mx-4 bg-gray-primary pt-4 pb-8 rounded-lg shadow-lg z-10 max-w-md"
          >
            <div className="text-lg font-bold border-b border-white/5 pb-2 px-8">
              <i
                className="cursor-pointer absolute text-sm top-5 right-5 text i-maki-cross"
                onClick={() => setResetPassword(false)}
              ></i>
              <span>Vous avez oublié votre mot de passe ?</span>
            </div>
            <div className="pt-4 px-8">
              <p className="mb-4">
                Veuillez entrer votre adresse email pour recevoir un lien de
                réinitialisation de mot de passe.
              </p>
              <Input
                placeholder="Votre email"
                type="email"
                onChange={(e) => setResetEmailInput(e)}
                value={resetEmailInput}
              />
              <Button
                className="mt-4"
                onClick={() => sendReset()}
                disabled={resetEmailInput === ""}
              >
                Envoyer
              </Button>
            </div>
          </motion.div>
        )}

        {step === "login" && (
          <>
            <Input
              placeholder="Email"
              className="mt-12"
              type="email"
              onChange={(e) => setEmailInput(e)}
              value={emailInput}
            />

            <Input
              placeholder="Password"
              className="mt-4"
              type="password"
              onChange={(e) => setPasswordInput(e)}
              value={passwordInput}
            />

            <Button
              className="mt-8"
              onClick={() => connectToRoom()}
              disabled={emailInput === "" || passwordInput === ""}
            >
              Se connecter et rejoindre la room
            </Button>

            <p
              className="text-sm underline cursor-pointer mt-4 opacity-80"
              onClick={() => setResetPassword(true)}
            >
              Mot de passe oublié ?
            </p>

            {loginError && (
              <p className="text-red-500 text-center text-sm mt-4">
                {loginError}
              </p>
            )}
          </>
        )}

        {step === "select-role" && (
          <>
            <RoleSelector
              roles={roles.filter(
                (role) => role !== "admin" && role !== "viewer"
              )}
              onRoleSelected={(role) => onRoleSelected(role)}
            />
          </>
        )}
      </div>
    </main>
  );
};
