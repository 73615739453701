import { gql } from "@apollo/client";
import { FULL_POLL_FRAGMENT } from "./poll";

export const FULL_EVENT_FRAGMENT = gql`
  ${FULL_POLL_FRAGMENT}

  fragment FullEventFragment on events {
    id
    created_at
    updated_at
    start_at
    application_id
    application {
      application_config_id
      id
      title
    }
    poll {
      ...PollFragment
    }
  }
`;

/**
 * Get the full details of the given event
 */
export const GET_EVENT = gql`
  ${FULL_EVENT_FRAGMENT}

  query GetEvent($eventId: uuid!) {
    events_by_pk(id: $eventId) {
      ...FullEventFragment
    }
  }
`;

/**
 * Subscribe to the media streamed of the given event
 */
export const EVENT_MEDIA_SUBSCRIPTION = gql`
  subscription onMediaStreamed($eventId: uuid!) {
    event_media(where: { event_id: { _eq: $eventId } }) {
      id
      media_id
      event_id
      position
      media {
        id
        name
        url
        type
      }
    }
  }
`;

/**
 * Subscribe to the modifications of the given event
 */
export const EVENT_SUBSCRIPTION = gql`
  ${FULL_EVENT_FRAGMENT}

  subscription onEventUpdated($eventId: uuid!) {
    event: events_by_pk(id: $eventId) {
      ...FullEventFragment
    }
  }
`;

/**
 * Set the event media streamed
 */
export const SET_MEDIA_STREAMED = gql`
  mutation setMediaEvent($eventId: uuid!, $mediaId: uuid!) {
    insert_event_media(
      objects: { event_id: $eventId, media_id: $mediaId, position: {} }
      on_conflict: {
        constraint: event_media_event_id_key
        update_columns: [position, media_id]
      }
    ) {
      returning {
        event_id
        id
        media_id
        position
        media {
          id
          name
          url
          type
        }
      }
    }
  }
`;

export const SET_EVENT_POLL = gql`
  mutation setEventPoll($eventId: uuid!, $pollId: uuid!) {
    update_events_by_pk(
      pk_columns: { id: $eventId }
      _set: { poll_id: $pollId }
    ) {
      id
    }
  }
`;

export const SET_EVENT_NOTE = gql`
  mutation SetEventNote($eventId: uuid!, $notes: String!) {
    update_events_by_pk(pk_columns: { id: $eventId }, _set: { notes: $notes }) {
      id
      notes
    }
  }
`;
