import { pusher } from "./Pusher";

export class Disconnecter {
  public static async start(userId: string) {
    const channel = pusher.subscribe(`private-rxs-${userId}`);

    channel.bind("user-connected", () => {
      window.location.href = "/";
      localStorage.clear();
    });
  }
}
