import { useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import type { VirtuosoHandle } from "react-virtuoso";
import { Chat } from "@/Features/Chat";
import { useChat } from "@/Features/Chat/Hooks/useChat";
import { StreamCanvas } from "@/Features/Streamcanvas";
import { Message } from "@/Features/Chat/Message";
import { ChatInput } from "@/Features/Chat/ChatInput";
import { MobileToolbar } from "@/Features/MobileToolbar";
import { Poll } from "@/Features/Poll/Poll";
import { useStore } from "@/Store";

export const MobileLayout = () => {
  const event = useStore((state) => state.event);
  const virtuoso = useRef<VirtuosoHandle>(null);

  const [showPoll, setShowPoll] = useState(false);

  const { messages, loading, useSubscribeChatMessage } = useChat();

  useEffect(() => {
    if (event?.poll) {
      setShowPoll(true);
    }
  }, [event?.poll?.id]);

  useSubscribeChatMessage();

  if (loading) return <div>Loading ...</div>;

  return (
    <div className="w-full grid grid-rows-[auto_1fr] c-mobile-layout  h-screen">
      <div className="c-vimeolive aspect-video md:aspect-none relative">
        <StreamCanvas />
      </div>

      <div className="flex flex-col overflow-hidden px-2 relative">
        {showPoll && (
          <div className="absolute bg-gray-primary z-50 w-full top-0 left-0 bg-opacity-100 px-4">
            {event!.poll ? (
              <Poll poll={event!.poll} />
            ) : (
              <p className="text-center py-4">Pas de sondages pour le moment</p>
            )}
          </div>
        )}

        <Virtuoso
          ref={virtuoso}
          className="c-chat-virtuoso overflow-y-scroll"
          initialTopMostItemIndex={49}
          followOutput={"smooth"}
          data={messages}
          itemContent={(_idx, message) => (
            <Message adminActions={true} message={message} />
          )}
        />

        <ChatInput />
        <MobileToolbar
          onPollClick={() => {
            setShowPoll((prev) => !prev);
          }}
        />
      </div>
    </div>
  );
};
