import { useEffect, useState } from "react";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import {
  CHAT_SUBSCRIPTION,
  FETCH_CHAT_MESSAGES,
  FETCH_MORE_MESSAGES,
} from "../Gql";
import type {
  ChatMessage,
  SubscrieChatMessage as SubscribeChatMessage,
} from "../Contracts";
import { useStore } from "@/Store/index";
import { Api } from "@/Services/Api";

export const useChat = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const event = useStore((state) => state.event);

  /**
   * Load initial chat messages from the database
   */
  const { data: fetchedMessages, loading } = useQuery(FETCH_CHAT_MESSAGES, {
    variables: { eventId: event?.id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!fetchedMessages?.event_chat_messages) return;

    setMessages(
      fetchedMessages.event_chat_messages
        .map((rawChatMessage: any) => ({
          id: rawChatMessage.id,
          content: rawChatMessage.content,
          username: rawChatMessage.username,
          role: rawChatMessage.role,
          created_at: rawChatMessage.created_at,
        }))
        .reverse()
    );
  }, [fetchedMessages]);

  /**
   * Send a chat message
   */
  const sendMessage = async (message: Partial<ChatMessage>) => {
    const result = await Api.sendChatMessage(
      event!.id,
      message.content!,
      localStorage.getItem("rxs:role")!
    ).catch((err) => err.response.data);

    if (result.message === "You are muted.") {
      return false;
    }

    if (
      "errors" in result &&
      result.errors.find((error: any) => error.rule === "maxLength")
    ) {
      return "E_TOO_LONG";
    }

    return !("error" in result);
  };

  /**
   * Subscribe to chat messages
   */
  const useSubscribeChatMessage = () => {
    const [searchParams] = useSearchParams();
    const { data } = useSubscription<SubscribeChatMessage>(CHAT_SUBSCRIPTION, {
      variables: { eventId: searchParams.get("event") },
    });

    useEffect(() => {
      if (!data) return;

      prependMessages(data.event_chat_messages);
    }, [data]);
  };

  function prependMessages(newMessages: ChatMessage[]) {
    const newMessagesCleaned = [...messages, ...newMessages].sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );

    const uniqueMessagesById = newMessagesCleaned.filter(
      (message, index, self) =>
        index === self.findIndex((m) => m.id === message.id)
    );

    setMessages(uniqueMessagesById);
  }

  const latestMessage = messages[messages.length - 1];

  const [loadMoreMessagesQuery] = useLazyQuery(FETCH_MORE_MESSAGES, {
    fetchPolicy: "no-cache",
  });

  async function loadMoreMessages() {
    if (!messages.length) return;

    const result = await loadMoreMessagesQuery({
      variables: {
        lastMessageDate: messages[0].created_at,
        eventId: event?.id,
      },
    });

    return result.data.event_chat_messages;
  }

  return {
    messages,
    loading,
    setMessages,
    sendMessage,
    prependMessages,
    loadMoreMessages,
    useSubscribeChatMessage,
    latestMessage,
  };
};
