import {
  useSubscription as useApolloSubscription,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useEffect, useState } from "react";
import {
  FETCH_PINNED_MESSAGE,
  PINNED_MESSAGE_SUBSCRIPTION,
  SET_PINNED_MESSAGE,
} from "../Gql/index";
import type { ChatMessage } from "../Contracts";
import { useStore } from "@/Store/index";

export const usePinnedMessage = () => {
  const event = useStore((state) => state.event);

  function useSubscription() {
    const [pinnedMessage, setPinnedMessage] = useState<ChatMessage | null>(
      null
    );

    /**
     * Initial query
     */
    const { data: fetchedPinnedMessage } = useQuery(FETCH_PINNED_MESSAGE, {
      variables: { eventId: event!.id },
    });

    useEffect(() => {
      setPinnedMessage(fetchedPinnedMessage?.events_by_pk?.pinned_message);
    }, [fetchedPinnedMessage]);

    /**
     * Subscription
     */
    const { data: updatedEvent } = useApolloSubscription(
      PINNED_MESSAGE_SUBSCRIPTION,
      { variables: { eventId: event!.id } }
    );

    useEffect(() => {
      if (!updatedEvent) return;
      setPinnedMessage(updatedEvent.events_by_pk.pinned_message);
    }, [updatedEvent]);

    return { pinnedMessage };
  }

  function useSetPinnedMessage() {
    const [mutate] = useMutation(SET_PINNED_MESSAGE);
    return (messageId: string) => {
      mutate({ variables: { eventId: event!.id, messageId } });
    };
  }

  function useUnsetPinnedMessage() {
    const [mutate] = useMutation(SET_PINNED_MESSAGE);
    return () => {
      mutate({ variables: { eventId: event!.id, messageId: null } });
    };
  }

  return { useSetPinnedMessage, useSubscription, useUnsetPinnedMessage };
};
