import clsx from "clsx";

interface Props {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  size?: "default" | "sm";
}

export const Button = (props: Props) => {
  const size = props.size ?? "default";

  return (
    <button
      disabled={props.disabled}
      className={clsx(
        "transition duration-200 ease-in-out hover:bg-dark",
        "bg-primary text-white outline-none hover:bg-primary-dark",
        "rounded-full hover:shadow-flat hover:scale-105 font-semibold",
        { "opacity-50 cursor-not-allowed": props.disabled },
        {
          "text-default px-6 py-2": size === "default",
          "text-sm  py-2 px-6": size === "sm",
        },
        props.className
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
