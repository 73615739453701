import clsx from "clsx";

interface Props {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export const Select = (props: Props) => {
  const { options, value, onChange, className, label } = props;

  return (
    <div className={className}>
      <label className="block mb-2 text-sm">{label}</label>

      <select
        className={clsx(
          "text-white bg-[#242424] text-sm rounded-lg",
          "block w-full py-2 px-3"
        )}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
