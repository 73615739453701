import { useEffect, useState } from "react";
import { gql, useQuery, useSubscription } from "@apollo/client";
import type { Poll as PollContract } from "@/Contracts";
import { VOTE_AGGREGATE_SUBSCRIPTION } from "@/Gql/poll";
import { Api } from "@/Services/Api";
import { useUser } from "@/Hooks/useUser";

export const usePoll = (poll: PollContract) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [displayResult, setDisplayResult] = useState(true);
  const { roles, userId } = useUser();

  const { data: userVote, refetch } = useQuery(
    gql`
      query hasAlreadyVoted($pollId: uuid!, $userId: uuid!) {
        poll_user_votes(
          where: {
            _and: [
              { poll_id: { _eq: $pollId } }
              { user_uuid: { _eq: $userId } }
            ]
          }
        ) {
          id
        }
      }
    `,
    { variables: { pollId: poll.id, userId } }
  );

  useEffect(() => {
    refetch();
  }, [poll.id]);

  useEffect(() => {
    setDisplayResult(!!userVote?.poll_user_votes.length);
  }, [userVote]);

  /**
   * Subscribe to the vote aggregate subscription
   *
   * Allows us to update the vote count/results in real time
   */
  const { data, loading: loadingSubscription } = useSubscription(
    VOTE_AGGREGATE_SUBSCRIPTION,
    { variables: { pollId: poll.id } }
  );

  const pollOptions = data?.polls_by_pk?.poll_options ?? [];

  /**
   * Count the total votes for the poll
   */
  const totalVotes: number = pollOptions.reduce(
    (acc: number, val: any) =>
      acc + val.poll_user_votes_aggregate.aggregate.count,
    0
  );

  /**
   * Percentage of votes for each option
   */
  const percentByOption: Record<string, number> = pollOptions
    .map((option: any) => ({
      optionId: option.id,
      percent: Math.round(
        (option.poll_user_votes_aggregate.aggregate.count / totalVotes) * 100
      ),
    }))
    .reduce(
      (acc: Record<string, number>, val: any) => ({
        ...acc,
        [val.optionId]: val.percent,
      }),
      {}
    );

  /**
   * Insert the user vote
   */
  const sendVote = async () => {
    if (!selectedOption) return;

    await Api.insertVote(poll.id, selectedOption);

    setDisplayResult(true);
  };

  const { isOnlyIntern } = useUser();
  function isAuthorisedToVote() {
    if (isOnlyIntern) return false;

    if (poll.type === "none") return true;

    return roles.includes(poll.type) || roles.includes("boss");
  }

  return {
    selectedOption,
    setSelectedOption,
    loading: loadingSubscription,
    totalVotes,
    percentByOption,
    sendVote,
    displayResult,
    isAuthorisedToVote,
  };
};
