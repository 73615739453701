import create from "zustand";
import type {
  LocalTrackPublication,
  RemoteTrackPublication,
  Room,
} from "livekit-client";
import type { Event, Media } from "../Contracts";

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

interface StoreContract {
  isAdmin: boolean;
  room: Room | null;
  setRoom: (room: Room) => void;

  event: Event | null;
  setEvent: (event: Event) => void;

  mediaStreamed: Media | null;
  setMediaStreamed: (media: Media | null) => void;

  trackStreamed: TrackPublication | null;
  setTrackStreamed: (track: TrackPublication | null) => void;

  user: any;
  setUser: (user: any) => void;
}

export const useStore = create<StoreContract>((set) => ({
  /**
   * Rooms
   */
  isAdmin: false,
  room: null,
  setRoom: (room: Room) => set({ room }),

  /**
   * Events
   */
  event: null,
  setEvent: (event: Event) => set({ event }),

  /**
   * Medias
   */
  mediaStreamed: null,
  setMediaStreamed: (mediaStreamed: Media | null) => set({ mediaStreamed }),

  trackStreamed: null,
  setTrackStreamed: (trackStreamed: TrackPublication | null) =>
    set({ trackStreamed }),

  user: null,
  setUser: (user) => set({ user }),
}));
