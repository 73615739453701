export const IconValid = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38482 6.9908L3.75003 5.35205C3.44567 5.04751 3.0327 4.87637 2.60211 4.87637C2.17153 4.87637 1.75856 5.04751 1.4542 5.35205C1.14966 5.65642 0.978516 6.06938 0.978516 6.49997C0.978516 6.93056 1.14966 7.34352 1.4542 7.64789L4.22503 10.4187L5.36899 11.5666C5.67335 11.8712 6.08632 12.0423 6.5169 12.0423C6.94749 12.0423 7.36046 11.8712 7.66482 11.5666L15.5419 3.68955C15.8462 3.38587 16.0173 2.97359 16.0173 2.54362C16.0173 2.11365 15.8462 1.70136 15.5419 1.39768C15.2375 1.09314 14.8246 0.921997 14.394 0.921997C13.9634 0.921997 13.5504 1.09314 13.2461 1.39768L7.68065 6.9908C7.37629 7.29534 6.96333 7.46648 6.53274 7.46648C6.10215 7.46648 5.68919 7.29534 5.38482 6.9908Z"
        fill="white"
      />
    </svg>
  );
};
