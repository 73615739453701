import Boss from "@/Assets/Bears/boss.png";
import Dai from "@/Assets/Bears/dai.png";
import Intern from "@/Assets/Bears/intern.png";
import Merch from "@/Assets/Bears/merch.png";
import Marketing from "@/Assets/Bears/rp.png";
import Music from "@/Assets/Bears/music.png";
import { Avatar } from "@/Components/UI/Avatar";

const roleData = {
  boss: Boss,
  image: Dai,
  intern: Intern,
  marketing: Marketing,
  merch: Merch,
  music: Music,
} as Record<string, string>;

export const BearAvatar = (props: {
  className?: string;
  role: string;
  queen: boolean;
}) => {
  const image = roleData[props.role] || roleData.intern;

  return (
    <div className="relative">
      {props.queen && (
        <i className="inline-block absolute z-10 i-emojione-crown text-xl -top-3 right-1 rotate-14" />
      )}
      {props.role === "boss" && !props.queen && (
        <i className="inline-block absolute z-10 i-fluent-emoji-crown text-xl -top-3 right-1 rotate-14" />
      )}
      <Avatar className={props.className} src={image} />
    </div>
  );
};
