/* eslint-disable react/no-unescaped-entities */
import { AnimatePresence, motion } from "framer-motion";
import { PollItem } from "./PollItem";
import { usePoll } from "./Hooks/usePoll";
import type { Poll as PollContract } from "@/Contracts";
import { Button } from "@/Components/UI/Button";

interface Props {
  poll: PollContract;
}

export const Poll = (props: Props) => {
  const {
    selectedOption,
    setSelectedOption,
    percentByOption,
    displayResult,
    sendVote,
    isAuthorisedToVote,
  } = usePoll(props.poll);

  return (
    <AnimatePresence>
      <motion.div
        className="sticky top-0 md:ml-4 py-4 md:bg-none"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
      >
        <p className="font-bold text-center md:text-left md:text-lg c-poll__title text-sm">
          {props.poll.title}
        </p>

        <div className="md:space-y-4 space-y-1 md:mt-4 mt-2">
          {[...props.poll.options]
            .sort((a, b) => percentByOption[b.id] - percentByOption[a.id])
            .map((pollOption, idx) => (
              <PollItem
                key={pollOption.id}
                index={idx}
                item={pollOption}
                allowVote={!displayResult && isAuthorisedToVote()}
                onSelect={() => setSelectedOption(pollOption.id)}
                isSelected={selectedOption === pollOption.id}
                result={percentByOption[pollOption.id] || 0}
              />
            ))}

          <div>
            {!displayResult && isAuthorisedToVote() && (
              <div className="flex justify-center sm:justify-start text-xs md:text-base mt-2 md:mt-0 c-poll__vote-btn">
                <Button onClick={() => sendVote()}>Voter !</Button>
              </div>
            )}

            {isAuthorisedToVote() === false && (
              <p className="text-center text-sm text-primary font-bold">
                Vous n'avez pas le rôle nécessaire pour voter à ce sondage.
              </p>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
