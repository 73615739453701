import { useLazyQuery } from "@apollo/client";
import defu from "defu";
import type { ApplicationConfig } from "@/Contracts";
import { GET_APPLICATION } from "@/Gql/application";

// eslint-disable-next-line import/no-mutable-exports
export let appConfig: ApplicationConfig["appConfig"] = {
  id: "",
  disableAudio: false,
  disableVideo: false,
  video: { allowedRoles: [] },
  audio: { allowedRoles: [] },
  isWeb3: false,
  emojis: [],
  chatRatePerMinute: 10,
  emojisSendThrottle: 1000,
  maxDisplayedEmojis: 10,
};

export const useAppConfig = ({
  immediate,
  appTitle,
}: {
  appTitle: string;
  immediate: boolean;
}) => {
  const [baseLoadAppConfig, { loading, called }] = useLazyQuery(
    GET_APPLICATION,
    { variables: { title: appTitle } }
  );

  const loadAppConfig = async () => {
    const { data } = await baseLoadAppConfig();
    appConfig = defu(
      data.application[0].application_config.app_config,
      appConfig
    );
    appConfig.id = data.application[0].application_config.id;
    return appConfig;
  };

  if (immediate && !called) {
    loadAppConfig();
  }

  return { loading, loadAppConfig, appConfig };
};
