import Pusher from "pusher-js";

export const pusher = new Pusher("206a273928a33c289c4f", {
  cluster: "eu",
  channelAuthorization: {
    endpoint: `${import.meta.env.VITE_API_URL}/pusher/auth`,
    transport: "ajax",
  },
});

export const reactionsChannel = pusher.subscribe("private-plk-reactions");
