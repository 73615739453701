import { useMutation } from "@apollo/client";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useStore } from "@/Store";
import { INSERT_POLL } from "@/Gql/poll";
import { SET_EVENT_POLL } from "@/Gql/events";
import { Input } from "@/Components/UI/Input";
import { Button } from "@/Components/UI/Button";
import AddIcon from "@/Assets/Icons/add.svg";
import DeleteIcon from "@/Assets/Icons/delete.svg";
import { Select } from "@/Components/UI/Select";

interface Props {
  visible: boolean;
  onPollAdded?: () => void;
  onCancel: () => void;
}

export const PollCreator = (props: Props) => {
  const [pollTitle, setPollTitle] = useState("");
  const [choices, setChoices] = useState<string[]>(["Oui", "Non"]);
  const [pollType, setPollType] = useState("none");

  const event = useStore((state) => state.event);
  const [insertPoll, { loading }] = useMutation(INSERT_POLL);
  const [setEventPoll, { loading: loadingSetEventPoll }] =
    useMutation(SET_EVENT_POLL);

  /**
   * Save the survey and update the event.poll_id
   */
  const saveSurvey = async () => {
    const {
      data: { insert_polls_one: insertedPoll },
    } = await insertPoll({
      variables: {
        title: pollTitle,
        pollOptions: choices.map((choice) => ({ title: choice })),
        eventId: event!.id,
        type: pollType,
      },
    });

    await setEventPoll({
      variables: { eventId: event!.id, pollId: insertedPoll.id },
    });

    if (props.onPollAdded) props.onPollAdded();
  };

  return (
    <AnimatePresence>
      {props.visible && (
        <motion.div
          className="z-0 bg-gray-primary sticky top-0 shadow-3xl pointer-events-auto rounded-r-xl"
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { duration: 0.2 } }}
          exit={{ x: -400, opacity: 0, transition: { duration: 0.2 } }}
        >
          <div className="p-4 pt-6">
            <div className="flex items-center justify-between">
              <p className="font-bold text-lg">Créer un sondage</p>
              <i
                className="cursor-pointer text-xs i-emojione-monotone-cross-mark inline-block"
                onClick={props.onCancel}
              />
            </div>

            <div className="pt-2 mt-2">
              <Input
                value={pollTitle}
                onChange={setPollTitle}
                size="sm"
                placeholder="Titre du sondage"
              />

              <Select
                className="mt-6"
                value={pollType}
                onChange={setPollType}
                label="Type du sondage"
                options={[
                  { label: "Aucun", value: "none" },
                  { label: "Boss", value: "boss" },
                  { label: "DA Image", value: "image" },
                  { label: "DA Musique", value: "music" },
                  { label: "DA Merch", value: "merch" },
                  { label: "DA Marketing", value: "marketing" },
                  { label: "Stagiaire", value: "intern" },
                ]}
              />

              <div className="mt-8">
                <div className="pb-4">Options :</div>

                {choices.map((choice, idx) => (
                  <AnimatePresence key={idx}>
                    <motion.div className="items-center relative flex justify-between space-x-2 pt-1 pb-1">
                      <Input
                        className="w-full pr-12"
                        value={choice}
                        onChange={(input) => {
                          const newChoices = choices.map((c, i) => {
                            if (i === idx) return input;
                            return c;
                          });
                          setChoices(newChoices);
                        }}
                        size="sm"
                        placeholder="Votre choix"
                      />

                      <div
                        onClick={() => setChoices([...choices, ""])}
                        className="absolute right-2 rounded-full cursor-pointer"
                      >
                        <img src={AddIcon} className="w-3.5 h-3.5" />
                      </div>
                      <div
                        onClick={() => {
                          if (choices.length === 1) return;
                          setChoices(choices.filter((_c, i) => i !== idx));
                        }}
                        className="absolute right-7 rounded-full cursor-pointer"
                      >
                        <img src={DeleteIcon} className="w-3.5 h-3.5" />
                      </div>
                    </motion.div>
                  </AnimatePresence>
                ))}
              </div>

              <Button
                onClick={() => saveSurvey()}
                size="sm"
                disabled={loading || loadingSetEventPoll}
                className="mr-2 mt-4"
              >
                Publier le sondage
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
